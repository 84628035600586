import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../../../api/ApiService';
// import axiosInstance from '../../../../../api/axiosInstance';
import { DetailToko } from '../../../../../Vendor/types';

interface JamOperasional {
    mulai: string;
    tutup: string;
}
interface WaktuOperasional {
    hari: string;
    buka: boolean;
    jam_operasional: JamOperasional;
}
interface UpdatedDetailTokoPayload {
    waktu_operasional: WaktuOperasional[];
    store_description: string;
}
interface UpdatedDetailTokoPayload {
    waktu_operasional: WaktuOperasional[];
    store_description: string;
}

interface DetailTokoState {
    dataDetailToko: DetailToko;
    loading: boolean;
    error: string | null;
}
interface DetailTokoState {
    dataDetailToko: DetailToko;
    loading: boolean;
    error: string | null;
}

const initialState: DetailTokoState = {
    dataDetailToko: {
      _id: '',
      namaToko: '',
      pengikut: 0,
      nilai_review: 0,
      store_description: '',
      profile_pict: '',
      address: {
        address_description: '',
        regency: '',
        province: '',
        code_pos: 0,
      },
      waktu_operasional: []
    },
    loading: false,
    error: null,
};

export const fetchDetailToko = createAsyncThunk<DetailToko, void>('DetailToko/fetchDetailToko', async () => {
    try {
        const response = await axiosInstance.get(`/vendor/detail/my-store`);
        console.log('response dari fetchDetailToko', response.data.data);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching detail toko vendor:', error);
        throw error;
    }
});

export const updatedProfilePict = createAsyncThunk<DetailToko, { formData: FormData }>(
    'DetailToko/updatedProfilePict',
    async ({ formData }) => {
        try {
            const response = await axiosInstance.put(`/vendor/update/toko`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Response dari updatedProfilePict:', response.data);
            return response.data.data;
        } catch (error: any) {
            console.error('Error updatedProfilePict:', error);
            throw new Error(error.response?.data?.message || 'Gagal mengupdate foto profile');
        }
    },
);

export const updatedDetailToko = createAsyncThunk<DetailToko, UpdatedDetailTokoPayload >(
    'DetailToko/updatedDetailToko',
    async ({ waktu_operasional, store_description }) => {
        try {
            const payload = {
                waktu_operasional,
                store_description,
            };
            const response = await axiosInstance.put(`/vendor/update/toko`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log('Response dari updatedDetailToko:', response.data);
            return response.data.data;
        } catch (error: any) {
            console.error('Error updatedDetailToko:', error);
            throw new Error(error.response?.data?.message || 'Gagal mengupdate data');
        }
    },
);

const TokoVendorSlice = createSlice({
    name: 'tokovendor',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailToko.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDetailToko.fulfilled, (state, action: PayloadAction<DetailToko>) => {
                state.loading = false;
                state.dataDetailToko = action.payload;
            })
            .addCase(fetchDetailToko.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch detail toko vendor';
                console.error('Error fetching detail:', action.error.message);
            })
            .addCase(updatedDetailToko.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updatedDetailToko.fulfilled, (state, action: PayloadAction<DetailToko>) => {
                state.loading = false;
                state.dataDetailToko = action.payload;
            })
            .addCase(updatedDetailToko.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to update detail toko vendor';
                console.error('Error updating data detail toko vendor:', action.error.message);
            });
    },
});

export const TokoVendorService = { action: TokoVendorSlice.actions, fetchDetailToko };
const tokoVendorReducer = TokoVendorSlice.reducer;
export default tokoVendorReducer;
