import React, { useEffect, useState } from 'react'
import { LogoUploadDocumentGradient } from '../../../../../../../konsumen/pages/register/sections/formAfterSelectRole/icons';

interface npwpInputProps {
    npwp: number
    setNpmwp: (nik: number | null) => void
    setNpwpFile: (ktp: File | null) => void
}
export default function NpwpInput({ npwp, setNpmwp, setNpwpFile }: npwpInputProps) {
    const [fileNPWP, setFileNPWP] = useState<File | null>()
    const [previewNpwpUrl, setPreviewNpwpUrl] = useState<string | null>(null);

    const handleNpwpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setNpmwp(value ? parseInt(value, 10) : null);
    };
    const handleSetFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files; 
        if (files && files.length > 0) {
            const file = files[0]; 
            setNpwpFile(file); 
            setFileNPWP(file); 
        }
    };    const handleRemoveNpwp = () => {
        setNpwpFile(null);
        setPreviewNpwpUrl(null);
        setFileNPWP(null)
        setPreviewNpwpUrl(null)
    }
    useEffect(() => {
        if (fileNPWP) {
            const objectUrl = URL.createObjectURL(fileNPWP);
            setPreviewNpwpUrl(objectUrl);
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, [fileNPWP]);
    return (
        <>
            <div className="mb-6">
                <label className="block text-sm font-medium text-gray-700 flex items-center">
                    NPWP <span className="text-red-500 ml-1">*</span>
                </label>
                <input
                    type="tel"
                    name="npwp"
                    value={npwp}
                    placeholder="Masukkan Nomor NPWP Anda"
                    className="w-full h-[50px] rounded-lg border border-gray-300 p-4 mt-2 bg-white placeholder-gray-400 outline-none focus:border-blue-500"
                    onChange={handleNpwpChange}
                />
                <p className="mt-1 text-xs text-gray-500">
                    Format NPWP tidak valid.
                </p>
            </div>

            {/* Unggah NPWP */}
            <div className="mb-6">
                <label className="block text-sm font-medium text-gray-700 flex items-center mb-2">
                    Unggah NPWP <span className="text-red-500 ml-1">*</span>
                </label>
                {previewNpwpUrl === null ? (
                    <div>
                        <div className="flex items-center justify-center mt-2">
                            <label className="flex flex-col items-center justify-center rounded-lg cursor-pointer border-2 border-dashed border-[#f46001] w-full h-[118px]">
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <LogoUploadDocumentGradient />
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">Unggah Media</p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">Unggah Foto (PNG,JPG)</p>
                                </div>
                                <input
                                    type="file"
                                    accept=".png,.jpg,.jpeg"
                                    name="imageNPWP"
                                    onChange={handleSetFile}
                                    className="dashed-border hidden"
                                />
                            </label>

                        </div>
                        <div className="w-[326px] h-[31px] mt-[10px]">
                            <p className="font-inter font-medium text-sm text-[#A6A7A8] text-left">
                                *Catatan : silakan lakukan unggah Foto, size file (PNG/JPG) Max 1 MB
                            </p>
                        </div>
                    </div>
                ) : (
                    <div className="relative flex items-center justify-start">
                        {previewNpwpUrl && (
                            <div className="relative">
                                <img src={previewNpwpUrl} alt="Preview NPWP" className="rounded-lg w-[200px] object-cover" />
                                <button
                                    onClick={handleRemoveNpwp}
                                    className="bg-[#D9D9D9] p-[3px] ml-[100px] mt-[-14px] text-[14px] rounded-full h-[25px] w-[25px] absolute top-2 -right-[10px] flex justify-center items-center"

                                >
                                    X
                                </button>
                            </div>
                        )}
                    </div>
                )}
                <p className="mt-1 text-xs text-gray-500">
                    *Ukuran file maksimum 1 MB.
                </p>
            </div>
        </>
    )
}
