import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useTypedSelector } from '../../redux/store';

interface ProtectedDashboardProps {
    children: ReactNode;
}

const ProtectedDashboardVendor: FC<ProtectedDashboardProps> = ({ children }) => {
    const userroleinfo = useTypedSelector((state) => state.UserInfo);
    const cookies = new Cookies();
    const token = cookies.get('token');

    if (!token || userroleinfo.role !== 'vendor') {
        alert('Anda harus login terlebih dahulu');
        return <Navigate to="/auth/login" replace />;
    }

    return <>{children}</>;
};

export default ProtectedDashboardVendor;
