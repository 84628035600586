import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from '../../../../../api/axiosInstance';
import { HelpItem, HelpItemSub, BantuanResponse } from '../../../../../Vendor/types';

interface HelpItemState {
    infoPusBan: HelpItem[];
    infoPusBanSub: HelpItemSub[];
    infoSpesifikSub: HelpItemSub[];
    dataList: BantuanResponse[],
    selectedById: string;
    loading: boolean;
    error: string | null;
}

const initialState: HelpItemState = {
    infoPusBan: [],
    infoPusBanSub: [],
    infoSpesifikSub: [],
    dataList: [], 
    loading: false,
    error: null,
    selectedById: '',
};

export const fetchCategoriesKendala = createAsyncThunk<HelpItem[], void>(
    'HelpItem/fetchCategoriesKendala',
    async () => {
        try {
            const response = await axiosInstance.get(`/category-utama-informasi-bantuan/list-judul-utama?role=vendor`);
            console.log('response dari fetchCategoriesKendala', response.data.data);
            return response?.data?.data;
        } catch (error) {
            console.error('Error fetching fetchCategoriesKendala:', error);
            throw error;
        }
    },
);
export const getCategoriesKendalaSub = createAsyncThunk<HelpItemSub[], any>(
    'HelpItemSub/getCategoriesKendalaSub',
    async (selectedId, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`/sub-category-informasi-bantuan/list-sub-judul/${selectedId}`);
            return response?.data?.data;
        } catch (error: any) {
            console.error('Error fetching getCategoriesKendalaSub:', error);
            return rejectWithValue(error.response?.data?.message || 'Failed to fetch getCategoriesKendalaSub');
        }
    },
);
export const getKendalaSubSpesifik = createAsyncThunk<BantuanResponse[], any>(
    'HelpItemSub/getKendalaSubSpesifik',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`/sub-category-informasi-bantuan/detail-sub-judul/${id}`);
            console.log('datalist --->', response?.data?.dataList)
            return response?.data?.dataList;
        } catch (error: any) {
            console.error('Error fetching getCategoriesKendalaSub:', error);
            return rejectWithValue(error.response?.data?.message || 'Failed to fetch getCategoriesKendalaSub');
        }
    },
);

const PusatBantuanVendorSlice = createSlice({
    name: 'pusatBantuan',
    initialState,
    reducers: {
      setSelectedById: (state, action: PayloadAction<string>) => {
        state.selectedById = action.payload;
    }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategoriesKendala.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCategoriesKendala.fulfilled, (state, action: PayloadAction<HelpItem[]>) => {
                state.loading = false;
                state.infoPusBan = action.payload;
            })
            .addCase(fetchCategoriesKendala.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch pusat bantuan vendor';
                console.error('Error fetching detail:', action.error.message);
            })
            .addCase(getCategoriesKendalaSub.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getCategoriesKendalaSub.fulfilled, (state, action: PayloadAction<HelpItemSub[]>) => {
                state.loading = false;
                state.infoPusBanSub = action.payload;
            })
            .addCase(getCategoriesKendalaSub.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch pusat bantuan sub vendor';
                console.error('Error fetching detail:', action.error.message);
            })
            .addCase(getKendalaSubSpesifik.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getKendalaSubSpesifik.fulfilled, (state, action: PayloadAction<BantuanResponse[]>) => {
                state.loading = false;
                state.dataList = action.payload;
            })
            .addCase(getKendalaSubSpesifik.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch pusat bantuan spesifik vendor';
                console.error('Error fetching detail:', action.error.message);
            });
    },
});


export const { setSelectedById } = PusatBantuanVendorSlice.actions;
export const PusatBantuanVendorService = {
    action: PusatBantuanVendorSlice.actions,
    fetchCategoriesKendala,
    getCategoriesKendalaSub,
};
const pusatBantuanVendorReducer = PusatBantuanVendorSlice.reducer;
export default pusatBantuanVendorReducer;
