import React, { useState } from 'react'
import { useTypedSelector } from '../../../../../../redux/store';
import StepIndicator from './stepIndicator';
import FirstStep from './stepForm/firstStep';
import SecondStep from './stepForm/secondStep';
import ThirdStep from './stepForm/thirdStep';

export default function PerusahaanFormSupplier() {
    const steps: string[] = ['Data Perusahaan', 'Data Gudang Individu', 'Data Penanggung Jawab'];
    const data = useTypedSelector((state) => state.ProdusenRegistIndividual);
    const [activeStep, setActiveStep] = useState<number>(3);
  return (
    <section className="w-full max-w-[800px] mx-auto p-4">
    {/* Step Indicator */}
    <div className="w-full mb-8">
        <StepIndicator steps={steps} activeStep={activeStep} />
        <div className="flex justify-center mb-8">
            <div className="flex space-x-6">
                {steps.map((step, index) => (
                    <div key={index} className="w-[85px] h-[34px] text-center">
                        <p className={`${index < activeStep ? 'text-[#f46001]' : 'text-[#C7C9D9]'} font-inter font-medium text-sm`}>
                            {step}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    </div>

    {/* Form Section */}
    {activeStep === 1 ? (
        <FirstStep />
    ) : activeStep === 2 ? (
        <SecondStep />
    ) : activeStep === 3 ? (
        <ThirdStep />
    ) : null}
</section>
  )
}
