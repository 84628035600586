import React, { useState } from 'react'
import { ArrowRightInputVendor } from '../../../../../../../../../Vendor/pages/registerVendor/sections/formAfterSelectRole/icons'
import GetIndonesianAddress from '../../../../../../../../../GlobalComponent/GetAddress'
import GetCoordinate from '../../../../../../../../../GlobalComponent/getCoordinate'
import LoadingSpinner from '../../../../../../../../../Vendor/Components/loadingSpinner';
import { googleMapsIcon } from '../../../../../../../../../konsumen/assets/images';
import { useAppDispatch, useTypedSelector } from '../../../../../../../../../redux/store';
import { setDataAdmin1, setStep, setId } from '../../../../../../../../../redux/store/reducers/Produsen/Auth/Individual';
import { LogoUploadDocumentGradient } from '../../../../../../../../../konsumen/pages/register/sections/formAfterSelectRole/icons';
import NameInput from '../../../../inputForm/nameInput';
import NikInput from '../../../../inputForm/nikInput';
import DetailAlamatInput from '../../../../inputForm/detailAlamatInput';
import KodePosInput from '../../../../inputForm/kodePosInput';
import NpwpInput from '../../../../inputForm/npwpInput';
import AddressAndCoordinateInput from '../../../../inputForm/addressAndCoordinateInput';

export default function FirstStep() {
    const dispatch = useAppDispatch()
    const id = useTypedSelector((state) => state.RegisterUser.id)
    const [fullname, setFullname] = useState<string>('');
    const [fullAddress, setFullAddress] = useState<string>('');
    const [detailAddress, setDetailAddress] = useState<string>('');
    const [postalCode, setPostalCode] = useState<string>('');
    const [loadingMaps, setLoadingMaps] = useState<boolean>(false);
    const [lat, setLat] = useState<number>(0);
    const [lng, setLng] = useState<number>(0);
    const [nik, setNik] = useState<number | null>();
    const [previewKTPUrl, setPreviewKTPUrl] = useState<string | null>(null);
    const [ktp, setKtp] = useState<File | null>(null);
    const [previewNpwpUrl, setPreviewNpwpUrl] = useState<string | null>(null);
    const [npwp, setNpwp] = useState<number | null>();
    const [npwpFile, setNpwpFile] = useState<File | null>(null);

    const [getIndonesianAddressModal, setGetIndonesianAddressModal] = useState<boolean>(false);
    const [getCoordinatesModals, setGetCoordinatesModals] = useState<boolean>(false);
    const alamat = fullAddress.split(', ');
    const showCoordinates: boolean = lat !== 0 && lng !== 0
    const isUnactiveEnabled: boolean =
        fullname.length > 0 &&
        fullAddress.length > 0 &&
        postalCode.length > 0

    const handleKtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setNik(value ? parseInt(value, 10) : null);
    };
    const handleRemoveNpwp = () => {
        setNpwpFile(null);
        setPreviewNpwpUrl(null);
    };
    const handleRemoveKtp = () => {
        setKtp(null);
        setPreviewKTPUrl(null);
    };
    const handleNpwpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setNpwp(value ? parseInt(value, 10) : null);
    };
    const handleSubmit = () => {
        if (id && fullname && fullAddress && postalCode) {
            const dataForAdmin1 = {
                nama: fullname,
                fullAddress,
                code_pos: postalCode,
                address_description: detailAddress,
                lat_pin_alamat: lat,
                long_pin_alamat: lng
            };
            dispatch(setStep(2));
            dispatch(setId(id));
            dispatch(setDataAdmin1(dataForAdmin1));
        }
    }

    return (
        <section className="text-start w-full">
            {/* Nama Lengkap */}
            <NameInput value={fullname} setName={setFullname} type={'person'} />
            {/* NIK and KTP Input */}
            <NikInput
                nik={nik || 0}
                setNik={setNik}
                setKtp={setKtp} />
            {/* Alamat Pemilik Toko */}
            <AddressAndCoordinateInput
                setPostalCode={setPostalCode} />
            {/* Detail Alamat */}
            <DetailAlamatInput
                detailAddress={detailAddress}
                setDetailAddress={setDetailAddress} />
            {/* Kode Pos */}
            <KodePosInput kodepos={postalCode} />
            {/* Input NPWP */}
            <NpwpInput
                npwp={npwp || 0}
                setNpmwp={setNpwp}
                setNpwpFile={setNpwpFile} />
            {/* Tombol Submit */}
            <button
                onClick={handleSubmit}
                disabled={!isUnactiveEnabled}
                className={`w-full max-w-[413px] h-[50px] rounded-[100px] py-4 mb-2 ${isUnactiveEnabled ? 'bg-custom-gradient cursor-pointer' : 'bg-[#F3F4F8] cursor-not-allowed'}`}
            >
                <p className={`${isUnactiveEnabled ? 'text-white' : 'text-[#BFBFBF]'} font-medium text-sm font-roboto capitalize`}>
                    lanjut
                </p>
            </button>
        </section>
    )
}
