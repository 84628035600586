import axios from 'axios';
import { persistStore } from 'redux-persist';
import Cookies from 'universal-cookie';
import store from '../redux/store';
// import { useNavigate } from 'react-router-dom';

const cookies = new Cookies();
const deviceIdKey = 'device_id';
// const navigate = useNavigate()

// Buat axios instance
export const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Interceptor untuk menambahkan header Authorization dan DeviceID setiap request
axiosInstance.interceptors.request.use(
    (config) => {
        const token = cookies.get('token');
        const deviceId = cookies.get(deviceIdKey);

        // Update header jika ada token dan deviceId
        if (token) {
            config.headers['Authorization'] = token;
        }

        if (deviceId) {
            config.headers['X-Header-DeviceID'] = deviceId;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Interceptor response untuk menangani error dan logout jika token kadaluarsa (401)
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            alert('Token kadaluarsa. Silakan login kembali.');
            // Clear token dan deviceId dari cookies jika token kadaluarsa
            cookies.remove('token', { path: '/' });
            cookies.remove(deviceIdKey, { path: '/' });
            delete axiosInstance.defaults.headers.common['Authorization'];
            delete axiosInstance.defaults.headers.common['X-Header-DeviceID'];

            // Redirect ke halaman login
            // navigate('/auth/login');
            alert('direct login')
        } else if (error.response) {
            console.error('Error response:', error.response.data);
            console.error('Error status:', error.response.status);
            console.error('Error headers:', error.response.headers);
        } else if (error.request) {
            console.error('Error request:', error.request);
        } else {
            console.error('Error message:', error.message);
        }
        return Promise.reject(error);
    }
);

// Fungsi untuk clear storage dan logout
const clearPersistedStorage = () => {
    const persistor = persistStore(store);
    persistor.purge();
    console.log('Redux Persist Storage cleared!');
};

export const removeToken = () => {
    clearPersistedStorage();
    cookies.remove('token', { path: '/' });
    cookies.remove(deviceIdKey, { path: '/' });
    delete axiosInstance.defaults.headers.common['Authorization'];
    delete axiosInstance.defaults.headers.common['X-Header-DeviceID'];
};