import React, { useState } from 'react'
import { ArrowRightInputVendor } from '../../../../../../../../../Vendor/pages/registerVendor/sections/formAfterSelectRole/icons'
import GetIndonesianAddress from '../../../../../../../../../GlobalComponent/GetAddress'
import GetCoordinate from '../../../../../../../../../GlobalComponent/getCoordinate'
import LoadingSpinner from '../../../../../../../../../Vendor/Components/loadingSpinner';
import { googleMapsIcon } from '../../../../../../../../../konsumen/assets/images';
import { useAppDispatch } from '../../../../../../../../../redux/store';
import { setStep } from '../../../../../../../../../redux/store/reducers/Produsen/Auth/Individual';
import NameInput from '../../../../inputForm/nameInput';
import NibInput from '../../../../inputForm/nibInput';
import PhoneNumberInput from '../../../../inputForm/phoneNumberInput';
import AddressAndCoordinateInput from '../../../../inputForm/addressAndCoordinateInput';
import NpwpInput from '../../../../inputForm/npwpInput';
import KodePosInput from '../../../../inputForm/kodePosInput';
import DetailAlamatInput from '../../../../inputForm/detailAlamatInput';

export default function FirstStep() {
    const dispatch = useAppDispatch()
    const [nik, setNik] = useState('');
    const [nib, setNib] = useState<string | null>();
    const [nibFile, setNibFile] = useState<File | null>(null);
    const [npmwp, setNpwp] = useState<number | null>();
    const [fileNpmwp, setFileNpwp] = useState<File | null>();
    const [phone, setPhone] = useState<string>('');
    const [fullname, setFullname] = useState<string>('');
    const [fullAddress, setFullAddress] = useState<string>('');
    const [addressDesc, setAddressDesc] = useState<string>()
    const [postalCode, setPostalCode] = useState<string>('');
    const [loadingMaps, setLoadingMaps] = useState<boolean>(false);
    const [lat, setLat] = useState<number>(0);
    const [lng, setLng] = useState<number>(0);
    const [getIndonesianAddressModal, setGetIndonesianAddressModal] = useState<boolean>(false);
    const [getCoordinatesModals, setGetCoordinatesModals] = useState<boolean>(false);
    const alamat = fullAddress.split(', ');
    const showCoordinates: boolean = lat !== 0 && lng !== 0
    const isUnactiveEnabled: boolean =
        fullname.length > 0 &&
        fullAddress.length > 0 &&
        postalCode.length > 0

    const handleSubmit = () => {
        dispatch(setStep(2));
    }
    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const file = e.target.files?.[0];
        console.log(`Uploaded File for ${field}:`, file);
    };
    return (
        <section className="text-start w-full">
            {/* Nama Lengkap */}
            <NameInput value={fullname} setName={setFullname} type={'person'} />
            {/* Input NIB */}
            <NibInput
                nibNumber={nib || ''}
                setNibNumber={setNib}
                setFileNib={setNibFile} />
            {/* No telepon kantor */}
            <PhoneNumberInput
                value={phone}
                setValue={setPhone}
            />
            {/* Alamat Pemilik Toko */}
            <AddressAndCoordinateInput setPostalCode={setPostalCode} />
            {/* Detail Alamat */}
            <DetailAlamatInput
                detailAddress={addressDesc || ''}
                setDetailAddress={setAddressDesc} />
            {/* Kode Pos */}
            <KodePosInput kodepos={postalCode} />
            {/* Input NPWP */}
            <NpwpInput
                npwp={npmwp || 0}
                setNpmwp={setNpwp}
                setNpwpFile={setFileNpwp} />
            {/* Tombol Submit */}
            <button
                onClick={handleSubmit}
                disabled={!isUnactiveEnabled}
                className={`w-full max-w-[413px] h-[50px] rounded-[100px] py-4 mb-2 ${isUnactiveEnabled ? 'bg-custom-gradient cursor-pointer' : 'bg-[#F3F4F8] cursor-not-allowed'}`}
            >
                <p className={`${isUnactiveEnabled ? 'text-white' : 'text-[#BFBFBF]'} font-medium text-sm font-roboto capitalize`}>
                    lanjut
                </p>
            </button>
        </section>
    )
}
