import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../../../../../redux/store";
import { setDataAdmin2, setStep } from "../../../../../../../../redux/store/reducers/supplier/auth/individual";
import { LogoUploadDocumentGradient } from "../../../../../../../../konsumen/pages/register/sections/formAfterSelectRole/icons";
import { ArrowRightInputVendor } from "../../../../../../../../Vendor/pages/registerVendor/sections/formAfterSelectRole/icons";
import { googleMapsIcon } from "../../../../../../../../konsumen/assets/images";
import LoadingSpinner from "../../../../../../../../Vendor/Components/loadingSpinner";
import GetIndonesianAddress from "../../../../../../../../GlobalComponent/GetAddress";
import GetCoordinate from "../../../../../../../../GlobalComponent/getCoordinate";

export default function SecondStep() {
  const dispatch = useAppDispatch();
  const [namaToko, setNamaToko] = useState('')
  const [nik, setNik] = useState<number | null>();
  const [npwp, setNpwp] = useState<number | null>();
  const [ktp, setKtp] = useState<File | null>(null);
  const [npwpFile, setNpwpFile] = useState<File | null>(null);
  const [previewKTPUrl, setPreviewKTPUrl] = useState<string | null>(null);
  const [previewNpwpUrl, setPreviewNpwpUrl] = useState<string | null>(null);
  const isDisabledBtn = nik !== null && npwp !== null && ktp !== null && npwpFile !== null;
  const [getCoordinatesModals, setGetCoordinatesModals] = useState<boolean>(false);
  const [fullAddress, setFullAddress] = useState<string>('');
  const alamat = fullAddress.split(', ');
  const [getIndonesianAddressModal, setGetIndonesianAddressModal] = useState<boolean>(false);
  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const showCoordinates: boolean = lat !== 0 && lng !== 0
  const [loadingMaps, setLoadingMaps] = useState<boolean>(false);
  const [detailAddress, setDetailAddress] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');

  const handleNpwpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNpwp(value ? parseInt(value, 10) : null);
  };
  const handleKtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNik(value ? parseInt(value, 10) : null);
  };
  const handleRemoveKtp = () => {
    setKtp(null);
    setPreviewKTPUrl(null);
  };
  const handleRemoveNpwp = () => {
    setNpwpFile(null);
    setPreviewNpwpUrl(null);
  };
  // General handle change for inputs
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log(`Input Changed: ${name}, Value: ${value}`);
  };

  const handleSubmit = () => {
    dispatch(setStep(3));
    if (ktp && npwpFile && nik && npwp) {
      const dataadmin = {
        nik,
        nomor_npwp: npwp,
        file_ktp: ktp,
        npwpFile
      }
      dispatch(setDataAdmin2(dataadmin));
    }
  };
  useEffect(() => {
    if (npwpFile) {
      const objectUrl = URL.createObjectURL(npwpFile);
      setPreviewNpwpUrl(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [npwpFile]);
  useEffect(() => {
    if (ktp) {
      const objectUrl = URL.createObjectURL(ktp);
      setPreviewKTPUrl(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [ktp]);
  return (
    <form>
      <GetIndonesianAddress
        showModal={getIndonesianAddressModal}
        CloseModals={setGetIndonesianAddressModal}
        setAddress={setFullAddress}
        setKodePos={setPostalCode}
      />
      <GetCoordinate
        showModal={getCoordinatesModals}
        handleCloseMap={setGetCoordinatesModals}
        setLat={setLat}
        setLong={setLng}
        alamat={`${alamat[2]},${alamat[1]}, Indonesia`}
      />
      {/* Nama Toko */}
      <div className="mb-6">
        <label className="flex items-center">
          <p className="font-medium text-sm font-inter text-custom-third">Nama Toko</p>
          <span className="text-[#E52427] ml-1">*</span>
        </label>
        <input
          className="input-password w-full max-w-[413px] font-poppins text-sm text-[#404040] border rounded-lg p-3"
          type="text"
          name="fullname"
          value={namaToko}
          onChange={(e) => setNamaToko(e.target.value)}
          placeholder="Masukan Nama Anda"
        />
      </div>
      {/* Alamat Toko */}
      <div className="mb-6 relative">
        <label className="flex items-center">
          <p className="font-medium text-sm font-inter text-custom-third">Alamat</p>
          <span className="text-[#E52427] ml-1">*</span>
        </label>
        <input
          className="input-password w-full max-w-[413px] font-poppins text-sm text-[#404040] border rounded-lg p-3 cursor-pointer"
          type="text"
          value={fullAddress}
          placeholder="Provinsi, Kota, Kecamatan, Kode Pos"
          onFocus={() => setGetIndonesianAddressModal(true)}
          readOnly
        />
        <div className="absolute bottom-4 right-4">
          <ArrowRightInputVendor />
        </div>
      </div>

      {/* Pin Alamat */}
      <div className="mb-6">
        <label className="flex items-center">
          <p className="font-medium text-sm font-inter text-custom-third">Pin Alamat</p>
          <span className="text-[#E52427] ml-1">*</span>
        </label>
        <div
          onClick={() => setGetCoordinatesModals(true)}
          className="w-full max-w-[413px] h-[50px] bg-white rounded-lg border border-[#f46001] flex justify-center items-center cursor-pointer mt-2"
        >
          <img src={googleMapsIcon} alt="icon-gmaps" />
          <p className="font-inter font-normal text-sm text-[#f46001] text-center ml-2">
            {loadingMaps ? (
              <div className="flex items-center justify-center">
                <LoadingSpinner width="18px" height="18px" />
                <span className="ml-2">Sedang Membuka Peta</span>
              </div>
            ) : showCoordinates ? (
              `${lat}, ${lng}`
            ) : (
              'Pilih Lewat Peta'
            )}
          </p>
        </div>
      </div>

      {/* Detail Alamat */}
      <div className="mb-6">
        <label className="flex items-center">
          <p className="font-medium text-sm font-inter text-custom-third">Detail Alamat</p>
          <span className="text-[#E52427] ml-1">*</span>
        </label>
        <textarea
          className="input-detail-alamat w-full max-w-[413px] font-poppins text-sm text-[#404040] resize-none border rounded-lg p-3"
          name="detailAddress"
          onChange={(e) => setDetailAddress(e.target.value)}
          value={detailAddress}
          placeholder="Contoh: Jl. Pahlawan No.12, Kel. Sukajadi"
        ></textarea>
      </div>

      {/* Kode Pos */}
      <div className="mb-6">
        <label className="flex items-center">
          <p className="font-medium text-sm font-inter text-custom-third">Kode Pos</p>
          <span className="text-[#E52427] ml-1">*</span>
        </label>
        <input
          className="input-password w-full max-w-[413px] font-poppins text-sm text-[#404040] border rounded-lg p-3"
          type="number"
          name="postalCode"
          readOnly
          value={postalCode}
          placeholder="Masukan Kode Pos Anda"
        />
      </div>
      {/* checkbox same address */}
      <section className="flex gap-4">
        <input type="checkbox"/>
        <p>Alamat Toko Sama Dengan Alamat Perusahaan</p>
      </section>

      {/* Buttons */}
      <div className="flex justify-between mt-8 gap-3">
        <button
          type="button"
          // onClick={() => dispatch(setStep(1))}
          className="w-1/3 h-[50px] border border-[#f46001] rounded-full 
                    bg-white py-3 px-3  font-roboto font-medium text-[14px] text-[#f46001] capitalize">
          Kembali
        </button>
        <button
          type="button"
          // onClick={handleSubmit}
          className={`w-2/3 ${isDisabledBtn ? 'bg-custom-gradient text-white' : 'bg-[#F3F4F8]'} 
            ${isDisabledBtn ? 'cursor-pointer' : 'cursor-not-allowed'} h-[50px] rounded-[100px] outline-none`}
        >
          Lanjut
        </button>
      </div>
    </form>
  );
}

