import { lazy, Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import LoadingComponent from '../GlobalComponent/LoadingComponent';
import CateringOtomatic from '../konsumen/pages/profile/menu/daftarTransaksiList/OtomaticCatering';
import Recurring from '../konsumen/pages/profile/menu/ketentuanDanPrivasi/menu/recurring';
import ProtectedDashboard from '../middleware/distributor/ProtectedDistri';
import AuthGuard from '../middleware/login/LoginMiddleware';
import ProtectedDashboardKonsumen from '../middleware/konsumen/ProtectKonsumen';
import ProtectedDashboardVendor from '../middleware/vendor/ProtectVendor';
import FormGuard from '../middleware/form/formMiddleware';
import DetailRiwayat from '../distributor/pages/Profile/Riwayat/DetailRiwayat';
import RegisterProdusen from '../produsen/pages/auth/regist';
import HomeProdusen from '../produsen/pages/home';
import RegisterSupplier from '../supplier/pages/auth/regist';
const DeleteAccountDashboard = lazy(() => import('./deleteAccount'));
const SettingPage = lazy(() => import('../Vendor/pages/profile/settingLanguage'));
const Pin = lazy(() => import('../konsumen/pages/pin'));
const UserDashBoard = lazy(() => import('./userDashboard'));
const NewPin = lazy(() => import('../konsumen/pages/pinNew'));
const UserOtp = lazy(() => import('../konsumen/pages/otpUser'));
const Invoice = lazy(() => import('../konsumen/pages/invoice'));
const SearchPage = lazy(() => import('../konsumen/pages/search'));
const HomeTest = lazy(() => import('../konsumen/pages/homeTest'));
const PinReset = lazy(() => import('../konsumen/pages/pinReset'));
const RivewPage = lazy(() => import('../Vendor/pages/reviewPage'));
const MyTokoPage = lazy(() => import('../Vendor/pages/myTokoPage'));
const IncomePage = lazy(() => import('../Vendor/pages/incomePage'));
const PinVerify = lazy(() => import('../konsumen/pages/PinVerify'));
const ConfirmPin = lazy(() => import('../konsumen/pages/pinConfirm'));
const DeleveryPage = lazy(() => import('../Vendor/pages/deleveryPage'));
const AnalisisPage = lazy(() => import('../Vendor/pages/AnalisisPage'));
const UlasanPage = lazy(() => import('../distributor/pages/UlasanPage'));
const ProfileDistri = lazy(() => import('../distributor/pages/Profile'));
const NewPassword = lazy(() => import('../konsumen/pages/passwordNew'));
const ErrorPage = lazy(() => import('../konsumen/Components/pageError'));
const Privasi = lazy(() => import('../distributor/pages/Profile/Privasi'));
const NotFoundPage = lazy(() => import('../konsumen/Components/notFound'));
const AddProductPage = lazy(() => import('../Vendor/pages/addProductPage'));
const RegisterVendor = lazy(() => import('../Vendor/pages/registerVendor'));
const Komplain = lazy(() => import('../distributor/pages/Profile/Komplain'));
const AddPinConfirm = lazy(() => import('../konsumen/pages/AddPinConfirm'));
const KeuanganPage = lazy(() => import('../distributor/pages/KeuanganPage'));
const ListProductPage = lazy(() => import('../Vendor/pages/listProductPage'));
const DashboardNotAcc = lazy(() => import('../Vendor/pages/dahsboardNotAcc'));
const Distributor = lazy(() => import('../distributor/pages/HomeDistributor'));
const PesananProduk = lazy(() => import('../distributor/pages/PesananProduk'));
const PaymentSuccess = lazy(() => import('../konsumen/pages/paymentSuccess'));
const PasswordVerify = lazy(() => import('../konsumen/pages/passwordVerify'));
const OrderProductPage = lazy(() => import('../Vendor/pages/orderProductPage'));
const RiwayatDistri = lazy(() => import('../distributor/pages/Profile/Riwayat'));
const SettingLanguage = lazy(() => import('../distributor/pages/Profile/Bahasa'));
const EditMyTokoPage = lazy(() => import('../Vendor/pages/myTokoPage/editMyToko'));
const DellAccountRequest = lazy(() => import('../konsumen/pages/dellAccountReq'));
const NotifikasiVendorPage = lazy(() => import('../Vendor/pages/notifikasiVendor'));
const DeletedAccountRequire = lazy(() => import('../konsumen/pages/deleteAccount'));
const PusatBantuanDashboard = lazy(() => import('./dashboardKonsumen/PusatBantuan'));
const PusatBantuanVendorDashboard = lazy(() => import('./DashboardVendor/pusatBantuan'));
const FormBiodataVendor = lazy(() => import('../Vendor/Components/addBiodataVendor'));
const EditProduct = lazy(() => import('../Vendor/pages/listProductPage/EditProducts'));
const PromotionProductPage = lazy(() => import('../Vendor/pages/promotionProductPage'));
const ProsesPengiriman = lazy(() => import('../distributor/pages/ProsesPengirimanPage'));
const SubmissionComplate = lazy(() => import('../Vendor/Components/SubmissionComplate'));
const DeleteAccountReason = lazy(() => import('../konsumen/pages/deleteAccountReason'));
const DetailProduct = lazy(() => import('../konsumen/Components/product/detailProduct'));
const FirstPage = lazy(() => import('../distributor/pages/KendaraanDriverPage/firstPage'));
const KendaraanDriverPage = lazy(() => import('../distributor/pages/KendaraanDriverPage'));
const PaymentProduct = lazy(() => import('../konsumen/Components/product/paymentProduct'));
const AddBioDistributor = lazy(() => import('../distributor/components/AddBioDistributor'));
const ManajerPengemudiPage = lazy(() => import('../distributor/pages/ManajerPengemudiPage'));
const FormBiodataKonsumen = lazy(() => import('../konsumen/Components/addBiodataKonsumen'));
const AddPromotion = lazy(() => import('../Vendor/Components/promotionProduct/AddPromotion'));
const LocationDelevery = lazy(() => import('../Vendor/Components/Delevery/locationDelevery'));
const DetailPengemudi = lazy(() => import('../distributor/pages/ManajerPengemudiPage/detail'));
const EditPromotion = lazy(() => import('../Vendor/Components/promotionProduct/editPromotion'));
const DetailProductCatalog = lazy(() => import('../Vendor/pages/listProductPage/DetailProduct'));
const FormBiodataDistributor = lazy(() => import('../distributor/components/AddBioDistributor'));
const ManajemenKendaraanPage = lazy(() => import('../distributor/pages/ManajemenKendaraanPage'));
const DashboardProfileDistributor = lazy(() => import('./dashboardDistributor/dashboardProfile'));
const DistributorProduct = lazy(() => import('../konsumen/Components/product/distributorProduct'));
const AnalisisDistributorPage = lazy(() => import('../distributor/pages/AnalisisDistributorPage'));
const DetailPesananProduk = lazy(() => import('../distributor/pages/PesananProduk/detailPesanan'));
const UpdatePesananProduk = lazy(() => import('../distributor/pages/PesananProduk/updatePesanan'));
const RutePengiriman = lazy(() => import('../distributor/pages/ProsesPengirimanPage/RutePerjalanan'));
const PaymentFirst = lazy(() => import('../konsumen/Components/product/paymentProduct/paymentFirst'));
const NotificationFoods = lazy(() => import('../Vendor/pages/notifikasiVendor/sections/recommendFood'));
const RegisterVendorOTP = lazy(() => import('../Vendor/pages/registerVendor/sections/RegisterVendorOTP'));
const WaitingPayment = lazy(() => import('../konsumen/Components/product/paymentProduct/waitingPayment'));
const SuccessPayment = lazy(() => import('../konsumen/Components/product/paymentProduct/successPayment'));
const UpdateSchool = lazy(() => import('../konsumen/pages/profile/menu/DataSekolah/section/updateSchool'));
const RoleRegisterVendor = lazy(() => import('../Vendor/pages/registerVendor/sections/roleRegisterVendor'));
const AddKendaraanPerusahaan = lazy(() => import('../distributor/pages/ManajemenKendaraanPage/addKendaraan'));
const RemainingPayment = lazy(() => import('../konsumen/Components/product/paymentProduct/remainingPayment'));
const NotificationOrderan = lazy(() => import('../Vendor/pages/notifikasiVendor/sections/orderanNotification'));
const AddKendaraanIndividu = lazy(() => import('../distributor/pages/KendaraanDriverPage/addKendaraanIndividu'));
const UpdatePengemudiPerusahaan = lazy(() => import('../distributor/pages/ManajerPengemudiPage/updatePengemudi'));
const SectionRoleRegisterVendor = lazy(() => import('../Vendor/pages/registerVendor/sections/formAfterSelectRole'));
const UpdateKendaraanPerusahaan = lazy(() => import('../distributor/pages/ManajemenKendaraanPage/updateKendaraan'));
const DetailKendaraanPerusahaan = lazy(() => import('../distributor/pages/ManajemenKendaraanPage/detailKendaraan'));
const UpdateKendaraanIndividu = lazy(() => import('../distributor/pages/KendaraanDriverPage/updateKendaraanIndividu'));
const PackageDistributor = lazy(() => import('../konsumen/Components/product/distributorProduct/packageDistributor'));
const SectionRoleRegisterDistributor = lazy(
    () => import('../distributor/pages/RegisterDistributor/sections/formAfterSelectRoleDistributor'),
);

// after CO
const OrderSuccess = lazy(() => import('../konsumen/pages/afterCheckOut/orderSuccess'));
const PartialPayment = lazy(() => import('../konsumen/pages/afterCheckOut/partialPayment'));
const DeadlinePayment = lazy(() => import('../konsumen/pages/afterCheckOut/deadlinePayment'));

// router ppob
const DashboardPpob = lazy(() => import('./ppob'));
const Topup = lazy(() => import('../konsumen/pages/topup'));
const PpobDasbord = lazy(() => import('../konsumen/pages/ppob'));
const Pulsa = lazy(() => import('../konsumen/pages/topup/components/pulsa'));
const PaketData = lazy(() => import('../konsumen/pages/topup/components/paketdata'));
const SuccsesValidate = lazy(() => import('../konsumen/pages/topup/components/validate'));

// router udinpay
const AddPin = lazy(() => import('../konsumen/pages/AddPin'));
const UdinPay = lazy(() => import('../konsumen/pages/dashboardUdinpay'));
const Ulasan = lazy(() => import('../konsumen/pages/profile/menu/ulasan'));
const Atm = lazy(() => import('../konsumen/pages/dashboardUdinpay/components/topup/atm'));
const Riwayat = lazy(() => import('../konsumen/pages/dashboardUdinpay/components/riwayat'));
const TopupUdin = lazy(() => import('../konsumen/pages/dashboardUdinpay/components/topup'));
const DaftarKeinginan = lazy(() => import('../konsumen/pages/profile/menu/daftarKeinginan'));
const Transfer = lazy(() => import('../konsumen/pages/dashboardUdinpay/components/transfer'));
const PengaturanBahasa = lazy(() => import('../konsumen/pages/profile/menu/pengaturanBahasa'));
const PesananDiKomplain = lazy(() => import('../konsumen/pages/profile/menu/pesananDiKomplain'));
const CreditCard = lazy(() => import('../konsumen/pages/dashboardUdinpay/components/topup/debit'));
const Mbanking = lazy(() => import('../konsumen/pages/dashboardUdinpay/components/topup/mbanking'));
const Alfamart = lazy(() => import('../konsumen/pages/dashboardUdinpay/components/topup/alfamart'));
const TransferUdinBank = lazy(() => import('../konsumen/pages/dashboardUdinpay/components/transfer'));
const Indomaret = lazy(() => import('../konsumen/pages/dashboardUdinpay/components/topup/indomaret'));
const Oneklik = lazy(() => import('../konsumen/pages/dashboardUdinpay/components/topup/oneklik/index'));
const TransferUdin = lazy(() => import('../konsumen/pages/dashboardUdinpay/components/transfer/transferudin'));
const TransferBank = lazy(() => import('../konsumen/pages/dashboardUdinpay/components/transfer/transferbank'));
const PesananSampai = lazy(
    () =>
        import(
            '../konsumen/pages/profile/menu/daftarTransaksiList/detailTransaksiList/Component/section/PesananSampai'
        ),
);
const DashboardSettingVendor = lazy(() => import('./DashboardVendor/dashboardSettingVendor'));
const SettingAccountVendor = lazy(() => import('../Vendor/pages/settingAccount'));
const SettingLanguageVendor = lazy(() => import('../Vendor/pages/profile/settingLanguage'));
const PusatBantuanVendor = lazy(() => import('../Vendor/pages/pusatBantuan'));
const KetentuanPrivasiVendor = lazy(() => import('../Vendor/pages/profile/privacyProvisions'));
const SubPusatBantuanVendor = lazy(() => import('../Vendor/pages/pusatBantuan/menus/sub'));
const SpesifikPusatBantuanVendor = lazy(() => import('../Vendor/pages/pusatBantuan/menus/spesifikSub'))
const JawabanPusatBantuanVendor = lazy(() => import('../Vendor/pages/pusatBantuan/menus/answer'));

//webPack Setting---------------------------------------
const LoginRegister = lazy(() => import('./Login'));
const Home = lazy(() => import('../konsumen/pages/home'));
const Discon = lazy(() => import('../konsumen/pages/Discon'));
const DashboardVendor = lazy(() => import('./DashboardVendor'));
const MBGPoint = lazy(() => import('../konsumen/pages/point'));
const LoginPage = lazy(() => import('../konsumen/pages/login'));
const Profile = lazy(() => import('../konsumen/pages/profile'));
const Category = lazy(() => import('../konsumen/pages/Category'));
const InputPIN = lazy(() => import('../konsumen/pages/InputPin'));
const DashboardKonsumen = lazy(() => import('./dashboardKonsumen'));
const DetailToko = lazy(() => import('../konsumen/pages/DetailToko'));
const RegisterForm = lazy(() => import('../konsumen/pages/register'));
const CartUpdate = lazy(() => import('../konsumen/pages/cartUpdate'));
const DashboardDistributor = lazy(() => import('./dashboardDistributor'));
const PusatBantuan = lazy(() => import('../konsumen/pages/pusatBantuan'));
const CartProducts = lazy(() => import('../konsumen/pages/CartsProducts'));
const PaymentUpdate = lazy(() => import('../konsumen/pages/paymentUpdate'));
const Setting = lazy(() => import('../konsumen/pages/profile/menu/Setting'));
const ChatSection = lazy(() => import('../konsumen/pages/profile/menu/Chat'));
const NotificationUser = lazy(() => import('../konsumen/pages/Notification'));
const Promo = lazy(() => import('../konsumen/pages/pusatBantuan/menu/promo'));
const GetDistributor = lazy(() => import('../konsumen/pages/pilihDistributor'));
const SectionForgotPasswword = lazy(() => import('../konsumen/pages/putNewPassword'));
const Pemesanan = lazy(() => import('../konsumen/pages/pusatBantuan/menu/pemesanan'));
const Transaksi = lazy(() => import('../konsumen/pages/pusatBantuan/menu/transaksi'));
const PilihLayanan = lazy(() => import('../konsumen/pages/pilihDistributor/section'));
const DataSekolah = lazy(() => import('../konsumen/pages/profile/menu/DataSekolah'));
const SectionOtpPutPassword = lazy(() => import('../konsumen/pages/otpForgotPassword'));
const Pengiriman = lazy(() => import('../konsumen/pages/pusatBantuan/menu/pengiriman'));
const SpesifikCategory = lazy(() => import('../konsumen/pages/Category/SpesifikCategory'));
const InputPageOTP = lazy(() => import('../konsumen/pages/register/sections/inputPageOTP'));
const KeamananAkun = lazy(() => import('../konsumen/pages/pusatBantuan/menu/keamananAkun'));
const TentangMBG = lazy(() => import('../konsumen/pages/pusatBantuan/menu/TentangMbg/inde'));
const SectionLoginOTP = lazy(() => import('../konsumen/pages/login/sections/sectionLoginOTP'));
const TokoFavorit = lazy(() => import('../konsumen/pages/profile/menu/MyProfile/TokoFavorit'));
const AkunDanAplikasi = lazy(() => import('../konsumen/pages/pusatBantuan/menu/akunDanAplikasi'));
const SaldoDanTagihan = lazy(() => import('../konsumen/pages/pusatBantuan/menu/saldoDanTagihan'));
const CreateRate = lazy(() => import('../konsumen/pages/profile/menu/daftarTransaksiList/Ulasan'));
const DaftarTransaksiList = lazy(() => import('../konsumen/pages/profile/menu/daftarTransaksiList'));
const RoleRegisterPage = lazy(() => import('../konsumen/pages/register/sections/roleRegisterPage'));
const ProductFavorit = lazy(() => import('../konsumen/pages/profile/menu/MyProfile/ProductFavorit'));
const AddSchool = lazy(() => import('../konsumen/pages/profile/menu/DataSekolah/section/addSchool'));
const KetentuanDanPrivasi = lazy(() => import('../konsumen/pages/profile/menu/ketentuanDanPrivasi'));
const ProductDetailUpdate = lazy(() => import('../konsumen/Components/product/detailProduct/detail'));
const NotPayment = lazy(() => import('../konsumen/pages/profile/menu/daftarTransaksiList/notPayment'));
const SectionRoleRegister = lazy(() => import('../konsumen/pages/register/sections/formAfterSelectRole'));
const AddPengemudiPerusahaan = lazy(() => import('../distributor/pages/ManajerPengemudiPage/addPengemudi'));
const SectionFormsRegister = lazy(() => import('../konsumen/pages/register/sections/sectionFormsRegister'));
const DetailSekolah = lazy(() => import('../konsumen/pages/profile/menu/DataSekolah/section/detailSekolah'));
const PutDataSekolah = lazy(() => import('../konsumen/pages/profile/menu/DataSekolah/section/putSchoolData'));
const Dikirim = lazy(() => import('../konsumen/pages/profile/menu/daftarTransaksiList/TransaksiLive/Dikirim'));
const StudentList = lazy(() => import('../konsumen/pages/profile/menu/DataSekolah/section/addSchool/studentList'));
const TrackingTransaction = lazy(() => import('../konsumen/pages/profile/menu/daftarTransaksiList/LacakTransaksi'));
const Berlangsung = lazy(() => import('../konsumen/pages/profile/menu/daftarTransaksiList/TransaksiLive/Berlangsung'));
const RegisterDistributorOTP = lazy(
    () => import('../distributor/pages/RegisterDistributor/sections/RegisterVendorOTP'),
);
const CancelTransaksiList = lazy(
    () => import('../konsumen/pages/profile/menu/daftarTransaksiList/cancelTransaksiList'),
);
const SuccesTransaksiList = lazy(
    () => import('../konsumen/pages/profile/menu/daftarTransaksiList/succesTransaksiList'),
);
const DetailTrasaksiList = lazy(() => import('../konsumen/pages/profile/menu/daftarTransaksiList/detailTransaksiList'));
const Diproses = lazy(
    () => import('../konsumen/pages/profile/menu/daftarTransaksiList/TransaksiLive/proccessTransaksi'),
);
const PesananSelesai = lazy(
    () => import('../konsumen/pages/profile/menu/daftarTransaksiList/TransaksiLive/PesananSelesai'),
);
//webPack Setting---------------------------------------

//

export const routes = createBrowserRouter([
    // Route Konsumen
    {
        path: '/',
        element: (
            <Suspense fallback={<LoadingComponent />}>
                <ProtectedDashboardKonsumen>
                    <DashboardKonsumen />
                </ProtectedDashboardKonsumen>
            </Suspense>
        ),
        errorElement: <NotFoundPage />,
        children: [
            {
                index: true,
                element: (
                    // <Suspense fallback={<LoadingComponent />}>
                    <Home />
                ),
                // </Suspense>
                errorElement: <ErrorPage />,
            },
            {
                path: '/invoice',
                element: (
                    <Suspense>
                        <Invoice />
                    </Suspense>
                ),
            },
            {
                path: 'successful-payment',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <PaymentSuccess />
                    </Suspense>
                ),
            },
            {
                path: 'discon',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <Discon />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'search',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <SearchPage />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'payment',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <PaymentUpdate />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            { path: 'get-layanan', element: <PilihLayanan />, errorElement: <ErrorPage /> },

            { path: 'Dashbord-Udinpay', element: <UdinPay />, errorElement: <ErrorPage /> },
            {
                path: 'detail-toko/:id',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <DetailToko />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'waiting-payment',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <WaitingPayment />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'success-payment',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <SuccessPayment />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            { path: 'reamining-payment', element: <RemainingPayment />, errorElement: <ErrorPage /> },
            {
                path: 'detail-product/:id',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <ProductDetailUpdate />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'category/:category',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <Category />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'detail-product/payment',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <PaymentProduct />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            { path: 'Dashbord-Udinpay/Topup', element: <TopupUdin />, errorElement: <ErrorPage /> },
            { path: 'detail-product/payment/1', element: <PaymentFirst />, errorElement: <ErrorPage /> },
            { path: 'Dashbord-Udinpay/Riwayat', element: <Riwayat />, errorElement: <ErrorPage /> },
            { path: 'Dashbord-Udinpay/Transfer', element: <Transfer />, errorElement: <ErrorPage /> },
            { path: 'detail-product/cart/update', element: <CartUpdate />, errorElement: <ErrorPage /> },
            {
                path: 'detail-product/update/:id',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <ProductDetailUpdate />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            { path: 'detail-product/cart-products', element: <CartUpdate />, errorElement: <ErrorPage /> },
            {
                path: 'detail-product/payment/distributor',
                element: <DistributorProduct />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'category/sub-category/:subCategory',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <Category />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            { path: 'get-distributor', element: <GetDistributor />, errorElement: <ErrorPage /> },
            {
                path: 'detail-product/payment/distributor/package',
                element: <PackageDistributor />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'category/subCategory/product-category/:categoryProduct',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <SpesifikCategory />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            //after CO
            {
                path: 'order/success',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <OrderSuccess />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'partial-payment',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <PartialPayment />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'payment-deadline',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <DeadlinePayment />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            //childs component UdinPay
            { path: 'Dashbord-Udinpay/Topup/atm', element: <Atm />, errorElement: <ErrorPage /> },
            { path: 'Dashbord-Udinpay/Topup/oneklik', element: <Oneklik />, errorElement: <ErrorPage /> },
            { path: 'Dashbord-Udinpay/Topup/indomart', element: <Indomaret />, errorElement: <ErrorPage /> },
            { path: 'Dashbord-Udinpay/Topup/alfamart', element: <Alfamart />, errorElement: <ErrorPage /> },
            { path: 'Dashbord-Udinpay/Topup/mbanking', element: <Mbanking />, errorElement: <ErrorPage /> },
            { path: 'Dashbord-Udinpay/Topup/credit-card', element: <CreditCard />, errorElement: <ErrorPage /> },
            // TRANSFER
            { path: 'Dashbord-Udinpay/Transfer', element: <TransferUdinBank />, errorElement: <ErrorPage /> },
            { path: 'Dashbord-Udinpay/Transfer/kesesamaudin', element: <TransferUdin />, errorElement: <ErrorPage /> },
            { path: 'Dashbord-Udinpay/Transfer/transferbank', element: <TransferBank />, errorElement: <ErrorPage /> },
            // Profile Konsumen Route
            {
                path: '/profile',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <Profile />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
                children: [
                    {
                        path: 'chat',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <ChatSection />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'ulasan',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <Ulasan />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'setting',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <Setting />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'notification',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <NotificationUser />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'ulas/:params',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <CreateRate />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'data-sekolah',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <DataSekolah />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'update-school',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <UpdateSchool />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'toko-favorite',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <TokoFavorit />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'pesanan-sampai',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <PesananSampai />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'data-sekolah/:id',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <DetailSekolah />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'product-favorite',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <ProductFavorit />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'daftar-keinginan',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <DaftarKeinginan />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'pengaturan-bahasa',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <PengaturanBahasa />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'pesanan-dikomplain',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <PesananDiKomplain />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'data-sekolah/tambah',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <AddSchool />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'data-sekolah/:id/ubah',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <PutDataSekolah />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'data-sekolah/detail/list-siswa',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                {' '}
                                <StudentList />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'detail-transaksi/:status/:orderId',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                {' '}
                                <DetailTrasaksiList />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'lacak/:id_toko/:id_distributor/:pengirimanId/:id_sekolah',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                {' '}
                                <TrackingTransaction />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'ketentuan&privasi',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <KetentuanDanPrivasi />
                            </Suspense>
                        ),
                        children: [
                            {
                                path: 'recurring',
                                element: (
                                    <Suspense fallback={<LoadingComponent />}>
                                        <Recurring />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: 'daftar-transaksi',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <DaftarTransaksiList />{' '}
                            </Suspense>
                        ),
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                path: 'catering-otomatic',
                                element: (
                                    <Suspense fallback={<LoadingComponent />}>
                                        <CateringOtomatic />{' '}
                                    </Suspense>
                                ),
                            },
                            {
                                path: 'Selesai',
                                element: (
                                    <Suspense fallback={<LoadingComponent />}>
                                        <PesananSelesai />
                                    </Suspense>
                                ),
                            },
                            {
                                path: 'Dikirim',
                                element: (
                                    <Suspense fallback={<LoadingComponent />}>
                                        <Dikirim />
                                    </Suspense>
                                ),
                            },
                            {
                                path: 'diproses',
                                element: (
                                    <Suspense fallback={<LoadingComponent />}>
                                        <Diproses />
                                    </Suspense>
                                ),
                            },
                            {
                                path: 'Berhasil',
                                element: (
                                    <Suspense fallback={<LoadingComponent />}>
                                        <SuccesTransaksiList />
                                    </Suspense>
                                ),
                            },
                            {
                                path: 'Dibatalkan',
                                element: (
                                    <Suspense fallback={<LoadingComponent />}>
                                        <CancelTransaksiList />
                                    </Suspense>
                                ),
                            },
                            {
                                path: 'berlangsung',
                                element: (
                                    <Suspense fallback={<LoadingComponent />}>
                                        <Berlangsung />
                                    </Suspense>
                                ),
                            },
                            {
                                path: 'Belum Bayar',
                                element: (
                                    <Suspense fallback={<LoadingComponent />}>
                                        <NotPayment />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                ],
            },
        ],
    },
    //dashboard user
    {
        path: '/user',
        element: (
            <Suspense fallback={<LoadingComponent />}>
                <UserDashBoard />
            </Suspense>
        ),
        children: [
            {
                path: 'otp',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <UserOtp />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'pin',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <Pin />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'new-pin',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <NewPin />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'pin/reset',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <PinReset />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'pin/verify',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <PinVerify />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'confirm-pin',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <ConfirmPin />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'new-password',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <NewPassword />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'password/verify',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <PasswordVerify />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            // //delete account
            // { path: 'delete-account', element: <DeletedAccountRequire />, errorElement: <ErrorPage /> },
            // { path: 'delete-account/request-submitted', element: <DellAccountRequest />, errorElement: <ErrorPage /> },
            // { path: 'delete-account/reason', element: <DeleteAccountReason />, errorElement: <ErrorPage /> },
        ],
    },
    //Route Delete account
    {
        path: '/delete-account',
        element: (
            <Suspense fallback={<LoadingComponent />}>
                <DeleteAccountDashboard />
            </Suspense>
        ),
        children: [
            //delete account
            { index: true, element: <DeletedAccountRequire />, errorElement: <ErrorPage /> },
            { path: 'request-submitted', element: <DellAccountRequest />, errorElement: <ErrorPage /> },
            { path: 'reason', element: <DeleteAccountReason />, errorElement: <ErrorPage /> },
        ],
    },
    //Route Produsen
    {
        path: '/produsen',
        element: <HomeProdusen />,
    },
    // Route form Register and Login
    {
        path: 'auth',
        element: (
            <Suspense fallback={<LoadingComponent />}>
                <AuthGuard>
                    <LoginRegister />
                </AuthGuard>
            </Suspense>
        ),
        errorElement: <NotFoundPage />,
        children: [
            {
                index: true,
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <NotFoundPage />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            { path: '*', element: <NotFoundPage />, errorElement: <ErrorPage /> },
            {
                path: 'otp',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <SectionOtpPutPassword />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            //produsen
            {
                path: 'registration/form/produsen',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <RegisterProdusen />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            //supplier
            {
                path: 'registration/form/supplier',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <RegisterSupplier />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'login',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <AuthGuard>
                            <LoginPage />
                        </AuthGuard>
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'register',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <RegisterForm />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'input-pin',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <InputPIN />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'otp-login',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <SectionLoginOTP />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'put-password',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <SectionForgotPasswword />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'role-register',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <RoleRegisterPage />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'register-vendor',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <RegisterVendor />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },

            {
                path: 'input-otp-vendor',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <RegisterVendorOTP />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'input-otp-register',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <InputPageOTP />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'register-input-form',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <SectionFormsRegister />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'submission-success',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <SubmissionComplate />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'select-role-register',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <SectionRoleRegister />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'role-register-vendor',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <RoleRegisterVendor />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'select-role-register-vendor',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <SectionRoleRegisterVendor />{' '}
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            // RegisterDistributor
            {
                path: 'register-distributor',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <AddBioDistributor />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'input-otp-distributor',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <RegisterDistributorOTP />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'select-role-register-distributor',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <SectionRoleRegisterDistributor />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
        ],
    },
    // form add bio
    {
        path: 'form',
        element: (
            <Suspense fallback={<LoadingComponent />}>
                <FormGuard>
                    <LoginRegister />
                </FormGuard>
            </Suspense>
        ),
        errorElement: <NotFoundPage />,
        children: [
            {
                path: 'add-bio-konsumen',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <FormBiodataKonsumen />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'adding-form-biodata',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <FormBiodataVendor />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'adding-form-biodata-distributor',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <FormBiodataDistributor />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'set-pin',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <AddPin />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'set-pin/confirm',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <AddPinConfirm />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
        ],
    },
    {
        path: '/hometest',
        element: <HomeTest />,
    },
    // Route Vendor
    {
        path: '/vendor',
        element: (
            <Suspense fallback={<LoadingComponent />}>
                <ProtectedDashboardVendor>
                    <DashboardVendor />
                </ProtectedDashboardVendor>
            </Suspense>
        ),
        errorElement: <NotFoundPage />,
        children: [
            { index: true, element: <NotFoundPage />, errorElement: <ErrorPage /> },
            { path: '/vendor/income', element: <IncomePage />, errorElement: <ErrorPage /> },
            { path: '/vendor/my-store', element: <MyTokoPage />, errorElement: <ErrorPage /> },
            { path: '/vendor/review', element: <RivewPage />, errorElement: <ErrorPage /> },
            { path: '/vendor/analysis', element: <AnalisisPage />, errorElement: <ErrorPage /> },
            { path: '/vendor/shipping', element: <DeleveryPage />, errorElement: <ErrorPage /> },
            { path: '/vendor/settings', element: <SettingPage />, errorElement: <ErrorPage /> },
            { path: '/vendor/list-order', element: <OrderProductPage />, errorElement: <ErrorPage /> },
            { path: '/vendor/verification', element: <DashboardNotAcc />, errorElement: <ErrorPage /> },
            { path: '/vendor/list-product/add-product', element: <AddProductPage />, errorElement: <ErrorPage /> },
            { path: '/vendor/list-product', element: <ListProductPage />, errorElement: <ErrorPage /> },
            { path: '/vendor/list-product/edit', element: <EditProduct />, errorElement: <ErrorPage /> },
            { path: '/vendor/my-store/update', element: <EditMyTokoPage />, errorElement: <ErrorPage /> },
            { path: '/vendor/shipping/location', element: <LocationDelevery />, errorElement: <ErrorPage /> },
            { path: '/vendor/promotion-product/add-promotion-product', element: <AddPromotion />, errorElement: <ErrorPage /> },
            { path: '/vendor/promotion-product/edit', element: <EditPromotion />, errorElement: <ErrorPage /> },
            { path: '/vendor/notification-vendor', element: <NotifikasiVendorPage />, errorElement: <ErrorPage /> },
            { path: '/vendor/notifikasi-orderan', element: <NotificationOrderan />, errorElement: <ErrorPage /> },
            { path: '/vendor/notification-vendor', element: <NotifikasiVendorPage />, errorElement: <ErrorPage /> },
            { path: '/vendor/promotion-product', element: <PromotionProductPage />, errorElement: <ErrorPage /> },
            { path: '/vendor/notification-vendor-foods', element: <NotificationFoods />, errorElement: <ErrorPage /> },
            { path: '/vendor/list-product/detail-product', element: <DetailProductCatalog />, errorElement: <ErrorPage />},
        ],
    },
    // dashboard setting vendor
    {
        path: '/vendor/profile',
        element: (
            <Suspense fallback={<LoadingComponent />}>
                <DashboardSettingVendor />
            </Suspense>
        ),
        errorElement: <NotFoundPage />,
        children: [
            { index: true, element: <NotFoundPage />, errorElement: <ErrorPage /> },
            { path: '/vendor/profile/account-settings', element: <SettingAccountVendor />, errorElement: <ErrorPage /> },
            { path: '/vendor/profile/language-settings', element: <SettingLanguageVendor />, errorElement: <ErrorPage />},
            { path: '/vendor/profile/privacy-provisions', element: <KetentuanPrivasiVendor />, errorElement: <ErrorPage />},
        ],
    },

    //route distributor
    {
        path: '/distributor',
        element: (
            <Suspense fallback={<LoadingComponent />}>
                <ProtectedDashboard>
                    <DashboardDistributor />
                </ProtectedDashboard>
            </Suspense>
        ),
        errorElement: <NotFoundPage />,
        children: [
            { index: true, element: <NotFoundPage />, errorElement: <ErrorPage /> },
            { path: '/distributor/dashboard', element: <Distributor />, errorElement: <ErrorPage /> },
            // RegisterDistributor
            { path: 'keuangan', element: <KeuanganPage />, errorElement: <ErrorPage /> },
            { path: 'ranting-ulasan', element: <UlasanPage />, errorElement: <ErrorPage /> },
            { path: 'pesanan-produk', element: <PesananProduk />, errorElement: <ErrorPage /> },
            { path: 'analisis', element: <AnalisisDistributorPage />, errorElement: <ErrorPage /> },
            { path: 'proses-pengiriman', element: <ProsesPengiriman />, errorElement: <ErrorPage /> },
            { path: 'kendaraan-driver', element: <KendaraanDriverPage />, errorElement: <ErrorPage /> },
            { path: 'kendaraan-driver/first-page', element: <FirstPage />, errorElement: <ErrorPage /> },
            { path: 'manajemen-pengemudi', element: <ManajerPengemudiPage />, errorElement: <ErrorPage /> },
            { path: 'manajemen-kendaraan', element: <ManajemenKendaraanPage />, errorElement: <ErrorPage /> },
            { path: 'proses-pengiriman/rute-pengiriman/:id', element: <RutePengiriman />, errorElement: <ErrorPage /> },
            {
                path: 'kendaraan-driver/add-kendaraan-individu',
                element: <AddKendaraanIndividu />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'pesanan-produk/detail-pesanan-produk/:id',
                element: <DetailPesananProduk />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'pesanan-produk/update-pesanan-produk/:id',
                element: <UpdatePesananProduk />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'kendaraan-driver/update-kendaraan-individu',
                element: <UpdateKendaraanIndividu />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'manajemen-kendaraan/add-kendaraan-perusahaan',
                element: <AddKendaraanPerusahaan />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'manajemen-pengemudi/add-pengemudi-perusahaan',
                element: <AddPengemudiPerusahaan />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'manajemen-pengemudi/detail-pengemudi-perusahaan/:id',
                element: <DetailPengemudi />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'manajemen-kendaraan/update-kendaraan-perusahaan/:id',
                element: <UpdateKendaraanPerusahaan />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'manajemen-pengemudi/update-pengemudi-perusahaan/:id',
                element: <UpdatePengemudiPerusahaan />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'manajemen-kendaraan/detail-kendaraan-perusahaan/:id',
                element: <DetailKendaraanPerusahaan />,
                errorElement: <ErrorPage />,
            },
        ],
    },

    //rute profile distributor
    {
        path: '/distributor/profile',
        element: (
            <Suspense fallback={<LoadingComponent />}>
                <ProtectedDashboard>
                    <DashboardProfileDistributor />
                </ProtectedDashboard>
            </Suspense>
        ),
        errorElement: <NotFoundPage />,
        children: [
            { index: true, element: <NotFoundPage />, errorElement: <ErrorPage /> },
            { path: '/distributor/profile/home', element: <ProfileDistri />, errorElement: <ErrorPage /> },
            { path: '/distributor/profile/pusat-bantuan', element: <PusatBantuan />, errorElement: <ErrorPage /> },
            {
                path: '/distributor/profile/pengaturan-bahasa',
                element: <SettingLanguage />,
                errorElement: <ErrorPage />,
            },
            { path: '/distributor/profile/ketentuan-privasi', element: <Privasi />, errorElement: <ErrorPage /> },
            {
                path: '/distributor/profile/riwayat-pengiriman',
                element: <RiwayatDistri />,

                errorElement: <ErrorPage />,
            },
            {
                path: '/distributor/profile/riwayat-pengiriman/detail-riwayat/:id',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <DetailRiwayat />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            { path: '/distributor/profile/pengiriman-komplain', element: <Komplain />, errorElement: <ErrorPage /> },
        ],
    },
    // Pusat Bantuan Routing
    {
        path: '/pusat-bantuan',
        element: (
            <Suspense fallback={<LoadingComponent />}>
                <PusatBantuanDashboard />
            </Suspense>
        ),
        errorElement: <ErrorPage />,
        children: [
            { index: true, element: <PusatBantuan />, errorElement: <ErrorPage /> },
            { path: 'promo', element: <Promo />, errorElement: <ErrorPage /> },
            { path: 'pemesanan', element: <Pemesanan />, errorElement: <ErrorPage /> },
            { path: 'transaksi', element: <Transaksi />, errorElement: <ErrorPage /> },
            { path: 'pengiriman', element: <Pengiriman />, errorElement: <ErrorPage /> },
            { path: 'tentang-mbg', element: <TentangMBG />, errorElement: <ErrorPage /> },
            { path: 'keamanan-akun', element: <KeamananAkun />, errorElement: <ErrorPage /> },
            { path: 'akun&aplikasi', element: <AkunDanAplikasi />, errorElement: <ErrorPage /> },
            { path: 'saldo&tagihan', element: <SaldoDanTagihan />, errorElement: <ErrorPage /> },
        ],
    },

    // route pusat bantuan vendor
    {
        path: '/help-center-vendor',
        element: (
            <Suspense fallback={<LoadingComponent />}>
                <PusatBantuanVendorDashboard />
            </Suspense>
        ),
        errorElement: <ErrorPage />,
        children: [
            { index: true, element: <PusatBantuanVendor />, errorElement: <ErrorPage /> },
            { path: 'sub', element: <SubPusatBantuanVendor />, errorElement: <ErrorPage /> },
            { path: 'specific-details', element: <SpesifikPusatBantuanVendor />, errorElement: <ErrorPage /> },
            { path: 'detailed-answer', element: <JawabanPusatBantuanVendor />, errorElement: <ErrorPage /> },

        ],
    },

    //custom Routing
    {
        path: '/point',
        element: (
            <Suspense fallback={<LoadingComponent />}>
                <MBGPoint />
            </Suspense>
        ),
        errorElement: <ErrorPage />,
    },

    // Route PPOB
    {
        path: '/ppob',
        element: (
            <Suspense fallback={<LoadingComponent />}>
                <DashboardPpob />
            </Suspense>
        ),
        errorElement: <NotFoundPage />,
        children: [
            { index: true, element: <NotFoundPage />, errorElement: <ErrorPage /> },
            {
                path: 'pulsa',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <Pulsa />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'PaketData',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <PaketData />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'ppob-topup',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <Topup />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'Ppobdashbord',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <PpobDasbord />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'succsesvalidate',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <SuccsesValidate />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
        ],
    },

    // Route UdinPay
    {
        path: '/udinpay',
        element: (
            <Suspense fallback={<LoadingComponent />}>
                <DashboardPpob />
            </Suspense>
        ),
        errorElement: <NotFoundPage />,
        children: [{ index: true, element: <NotFoundPage />, errorElement: <ErrorPage /> }],
    },

   
]);
