import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import store from './redux/store';
import { BrowserRouter } from 'react-router-dom';

const container = document.getElementById('root');
if (container) {
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <Provider store={store}>
                {/* <PersistGate loading={null} persistor={persistor}> */}
                <App />

                {/* </PersistGate> */}
            </Provider>
        </React.StrictMode>,
    );
}
