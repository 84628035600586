import React from 'react'
interface jabatanProps {
    value: string;
    setValue: (value : string ) => void
}
export default function JabatanInput({value, setValue} : jabatanProps) {
    return (
        <div className="mb-6">
            <label className="flex items-center">
                <p className="font-medium text-sm font-inter text-custom-third">Jabatan Penanggung Jawab</p>
                <span className="text-[#E52427] ml-1">*</span>
            </label>
            <input
                className="input-password w-full max-w-[413px] font-poppins text-sm text-[#404040] border rounded-lg p-3"
                type="text"
                name="fullname"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder="Masukan Jabatan Penanggung Jawab"
            />
        </div>
    )
}
