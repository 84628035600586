import React from 'react';

interface PhoneNumberProps {
  value: string;
  setValue: (value: string) => void;
}

export default function PhoneNumberInput({ value, setValue }: PhoneNumberProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    
    const regex = /^[0-9]{0,14}$/;
    if (regex.test(inputValue)) {
      setValue(inputValue);
    }
  };

  return (
    <div className="mb-6">
      <label className="flex items-center">
        <p className="font-medium text-sm font-inter text-custom-third">No Telepon Kantor</p>
        <span className="text-[#E52427] ml-1">*</span>
      </label>
      <input
        className="input-password w-full max-w-[413px] font-poppins text-sm text-[#404040] border rounded-lg p-3"
        type="text"
        name="phoneNumber"
        value={value}
        onChange={handleChange}
        placeholder="08xx-xxxx-xxxx"
      />
    </div>
  );
}
