import React, { useEffect, useState } from "react";
import { setDataAdmin2, setStep } from "../../../../../../../../../redux/store/reducers/Produsen/Auth/Individual";
import { useAppDispatch, useTypedSelector } from "../../../../../../../../../redux/store";
import { LogoUploadDocumentGradient } from "../../../../../../../../../konsumen/pages/register/sections/formAfterSelectRole/icons";
import { ArrowRightInputVendor } from "../../../../../../../../../Vendor/pages/registerVendor/sections/formAfterSelectRole/icons";
import LoadingSpinner from "../../../../../../../../../Vendor/Components/loadingSpinner";
import { googleMapsIcon } from "../../../../../../../../../konsumen/assets/images";
import AddressAndCoordinateInput from "../../../../inputForm/addressAndCoordinateInput";
import DetailAlamatInput from "../../../../inputForm/detailAlamatInput";
import KodePosInput from "../../../../inputForm/kodePosInput";
import SameAddressCheckBox from "../../../../inputForm/sameAddressCheckBox";
import NameInput from "../../../../inputForm/nameInput";

export default function SecondStep() {
  const dispatch = useAppDispatch();
  const [nik, setNik] = useState('');
  const [npmwp, setNpwp] = useState('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [fullname, setFullname] = useState<string>('');
  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const [detailAddress, setDetailAddress] = useState('')

  const isDisabledBtn = nik !== '' && npmwp !== '';
  const showCoordinates: boolean = lat !== 0 && lng !== 0

  // General handle change for inputs
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log(`Input Changed: ${name}, Value: ${value}`);
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    const file = e.target.files?.[0];
    console.log(`Uploaded File for ${field}:`, file);
  };

  const handleSubmit = () => {
    dispatch(setStep(3));
  };
  return (
    <form>
      {/* Nama Toko */}
      <NameInput value={fullname} setName={setFullname} type={'store'} />
      {/* Alamat */}
      <AddressAndCoordinateInput
        setPostalCode={setPostalCode} />
      {/* Detail Alamat */}
      <DetailAlamatInput
        detailAddress={detailAddress}
        setDetailAddress={setDetailAddress} />
      {/* Kode Pos */}
      <KodePosInput kodepos={postalCode} />
      {/* same address */}
      <SameAddressCheckBox />
      {/* Buttons */}
      <div className="flex justify-between mt-8 gap-3">
        <button
          type="button"
          onClick={() => dispatch(setStep(1))}
          className="w-1/3 h-[50px] border border-[#f46001] rounded-full 
                    bg-white py-3 px-3  font-roboto font-medium text-[14px] text-[#f46001] capitalize">
          Kembali
        </button>
        <button
          type="button"
          onClick={handleSubmit}
          className={`w-2/3 ${isDisabledBtn ? 'bg-custom-gradient text-white' : 'bg-[#F3F4F8]'} 
            ${isDisabledBtn ? 'cursor-pointer' : 'cursor-not-allowed'} h-[50px] rounded-[100px] outline-none`}
        >
          Lanjut
        </button>
      </div>
    </form>
  );
}

