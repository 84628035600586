import React, { useState } from 'react'
import { useAppDispatch } from '../../../../../redux/store';
import IndividualFormSupplier from './individualForm';
import PerusahaanFormSupplier from './perusahaanSection';

export default function RegistForm() {
    const dispatch = useAppDispatch()
    const [activeTab, setActiveTab] = useState('Individual');
    const handleTabClick = (tabName: string) => {
        setActiveTab(tabName);
        // dispatch(setRegisterAs(tabName))
    };
  return (
    <section className="w-full md:w-auto bg-white rounded-xl border shadow-md py-8 px-6 md:py-[56px] md:px-[57px] mt-24 md:mt-0">
    {/* ==== START WRAPPER MOVE TAB INDIVIDUAL/COMPANY ==== */}
    <div className="flex justify-center gap-6 mb-8 w-full md:w-[413px]">
        <button onClick={() => handleTabClick('Individual')}>
            <div className={`w-1/2 md:w-[206px] ${activeTab === 'Individual' ? 'border-b-[#f46001] bg-custom-gradient bg-clip-text text-transparent border-b-[2px]' : ''}`}>
                <p>Individual</p>
            </div>
        </button>
        <button onClick={() => handleTabClick('Perusahaan')}>
            <div className={`w-1/2 md:w-[206px] ${activeTab === 'Perusahaan' ? 'border-b-[#f46001] bg-custom-gradient bg-clip-text text-transparent border-b-[2px]' : ''}`}>
                <p>Perusahaan</p>
            </div>
        </button>
    </div>
    <div className="mb-8 flex justify-center items-center bg-[#E6F2F1] py-2">
        <p className="font-inter font-medium text-sm text-[#007F73]">
            Kamu sekarang mendaftar sebagai Supplier
        </p>
    </div>
    {/* ==== END WRAPPER MOVE TAB INDIVIDUAL/COMPANY ==== */}
    <div className="w-full md:w-[413px] mx-auto"> {/* Padding top ditambahkan */}
        <div className="w-full md:w-[335px] h-auto mx-auto mb-4">
            <p className="text-center text-[24px] md:text-[32px] font-bold font-inter leading-tight text-[#f46001]">
                Daftar Mudah Bergabung Bersama <span className='bg-custom-gradient bg-clip-text text-transparent'>MBG</span>
            </p>
        </div>
        <p className="font-inter font-medium text-sm md:text-base text-center text-custom-third mb-4">
            Lengkapi data - data sebagai berikut
        </p>
        {activeTab === 'Individual' ? (
            <IndividualFormSupplier />
        ) : (
            <PerusahaanFormSupplier />
        )}
    </div>
</section>
  )
}
