import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from '../../../../../api/axiosInstance';
import { BusinessProfile } from '../../../../../Vendor/types';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const token = cookies.get('token');
const deviceId = cookies.get('device_id');

interface VendorDetailState {
    datasDetailVendor: BusinessProfile;
    isRoleIndividual: boolean;
    loading: boolean;
    error: string | null;
}

const initialState: VendorDetailState = {
    datasDetailVendor: {
        _id: '',
        nama: '',
        nik: '',
        file_ktp: '',
        namaBadanUsaha: '',
        nomorAktaPerusahaan: '',
        npwpFile: '',
        nomorNpwpPerusahaan: '',
        nomorNpwp: '',
        address: [],
        noTeleponKantor: '',
        userId: {
            _id: '',
            saldo: 0,
            isDetailVerified: false,
            email: {
                isVerified: false,
                content: '',
            },
            phone: {
                isVerified: false,
                content: '',
            },
        },
        jenis_kelamin: '',
        jenis_perusahaan: '',
        legalitasBadanUsaha: '',
        profile_pict: '',
        __v: 0,
        tanggal_lahir: '',
        nilai_pinalti: 0,
    },
    isRoleIndividual: false,
    loading: false,
    error: null,
};

export const fetchVendorDetail = createAsyncThunk<BusinessProfile, void>(
    'BusinessProfile/fetchVendorDetail',
    async (_, { dispatch }) => { 
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/vendor/detail`, {
                headers: {
                    Authorization: token,
                    'X-Header-DeviceID': deviceId,
                    'Content-Type': 'application/json',
                },
            });
            dispatch(getRoleIndividual(response?.data?.isIndividu));
            return response?.data?.datas;
        } catch (error) {
            console.error('Error fetching vendor detail:', error);
            throw error;
        }
    },
);

export const updatedVendor = createAsyncThunk<BusinessProfile, { formData: FormData }>(
    'BusinessProfile/updatedVendor',
    async ({ formData }) => {
        try {
            const response = await axiosInstance.put(`/vendor/update`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data.data;
        } catch (error) {
            console.error('Error fetching distributor:', error);
            throw error;
        }
    },
);

const vendorDetailSlice = createSlice({
    name: 'vendorDetail',
    initialState,
    reducers: {
        getRoleIndividual(state, action: PayloadAction<boolean>) {
            state.isRoleIndividual = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchVendorDetail.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchVendorDetail.fulfilled, (state, action: PayloadAction<BusinessProfile>) => {
                state.loading = false;
                state.datasDetailVendor = action.payload;
            })
            .addCase(fetchVendorDetail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch vendor detail';
                console.error('Error fetching vendor detail:', action.error.message);
            });
    },
});

export const { getRoleIndividual } = vendorDetailSlice.actions;
export const vendorDetailService = { actions: vendorDetailSlice.actions, fetchVendorDetail };
const vendorDetailReducer = vendorDetailSlice.reducer;
export default vendorDetailReducer;
