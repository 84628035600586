import React from 'react'
interface detailAlamatProps {
    detailAddress: string
    setDetailAddress: (nik: string) => void
}

export default function DetailAlamatInput({detailAddress, setDetailAddress}: detailAlamatProps) {
    return (
        <div className="mb-6">
            <label className="flex items-center">
                <p className="font-medium text-sm font-inter text-custom-third">Detail Alamat</p>
                <span className="text-[#E52427] ml-1">*</span>
            </label>
            <textarea
                className="input-detail-alamat w-full max-w-[413px] font-poppins text-sm text-[#404040] resize-none border rounded-lg p-3"
                name="detailAddress"
                onChange={(e) => setDetailAddress(e.target.value)}
                value={detailAddress}
                placeholder="Contoh: Jl. Pahlawan No.12, Kel. Sukajadi"
            ></textarea>
        </div>
    )
}
