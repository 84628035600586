import React, { useState } from 'react'
import { googleMapsIcon } from '../../../../../../../../../konsumen/assets/images';
import LoadingSpinner from '../../../../../../../../../Vendor/Components/loadingSpinner';
import { ArrowRightInputVendor } from '../../../../../../../../../Vendor/pages/registerVendor/sections/formAfterSelectRole/icons';
import GetIndonesianAddress from '../../../../../../../../../GlobalComponent/GetAddress';
import GetCoordinate from '../../../../../../../../../GlobalComponent/getCoordinate';
import NameInput from '../../../../inputForm/nameInput';
import NikInput from '../../../../inputForm/nikInput';
import AddressAndCoordinateInput from '../../../../inputForm/addressAndCoordinateInput';
import DetailAlamatInput from '../../../../inputForm/detailAlamatInput';
import KodePosInput from '../../../../inputForm/kodePosInput';
import NpwpInput from '../../../../inputForm/npwpInput';
import JabatanInput from '../../../../inputForm/jabatanInput';

export default function ThirdStep() {
  const [getIndonesianAddressModal, setGetIndonesianAddressModal] = useState(false);
  const [getCoordinatesModals, setGetCoordinatesModals] = useState(false);
  const [loadingMaps, setLoadingMaps] = useState<boolean>(false);
  const [fullname, setFullname] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [fullAddress, setFullAddress] = useState<string>('');
  const [addressDesc, setAddressDesc] = useState<string>('')
  const alamat = fullAddress.split(', ');
  const [jabatan, setJabatan] = useState('')
  const [NIK, setNIK] = useState<number | null>(null)
  const [KTP, setKTP] = useState<File | null>(null)
  const [npwp, setNpwp] = useState<number | null>(null);
  const [fileNpwp, setFileNpwp] = useState<File | null>(null)
  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const showCoordinates: boolean = lat !== 0 && lng !== 0
  const isUnactiveEnabled: boolean =
    fullname.length > 0 &&
    fullAddress.length > 0 &&
    postalCode.length > 0

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    const file = e.target.files?.[0];
    console.log(`Uploaded File for ${field}:`, file);
  };
  const handleSubmit = () => {
    alert('registrasi berhasil anda akan dilanjutkan ke halaman create password');
  }
  return (
    <section className="text-start w-full">
      {/* Nama Lengkap */}
      <NameInput value={fullname} setName={setFullname} type={'responsible'} />
      {/* Jabatan penanggung jawab */}
      <JabatanInput
        value={jabatan} setValue={setJabatan} />
      {/* Input NIK */}
      <NikInput
        nik={NIK || 0}
        setNik={setNIK}
        setKtp={setKTP} />
      {/* Alamat Pemilik Toko */}
      <AddressAndCoordinateInput setPostalCode={setPostalCode} />
      {/* Detail Alamat */}
      <DetailAlamatInput detailAddress={addressDesc} setDetailAddress={setAddressDesc} />
      {/* Kode Pos */}
      <KodePosInput kodepos={postalCode} />
      {/* Input NPWP */}
      <NpwpInput
        npwp={npwp || 0}
        setNpmwp={setNpwp}
        setNpwpFile={setFileNpwp} />
      {/* Tombol Submit */}
      <button
        onClick={handleSubmit}
        disabled={!isUnactiveEnabled}
        className={`w-full max-w-[413px] h-[50px] rounded-[100px] py-4 mb-2 ${isUnactiveEnabled ? 'bg-custom-gradient cursor-pointer' : 'bg-[#F3F4F8] cursor-not-allowed'}`}
      >
        <p className={`${isUnactiveEnabled ? 'text-white' : 'text-[#BFBFBF]'} font-medium text-sm font-roboto capitalize`}>
          lanjut
        </p>
      </button>
    </section>
  )
}
