import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { NotificationState } from '../../interfaces';
import { ResponseNotification } from '../../../../Vendor/types';
import axiosInstance from '../../../../api/axiosInstance';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const token = cookies.get('token');
const deviceId = cookies.get('device_id');

const initialState: NotificationState = {
    dataNotification: {
        jenis: '',
        title: '',
        message: '',
        rekomendasiMakanan: [],
        waktu: '',
    },
    loadingResponse: false,
    errorResponse: null,
    countBadge: 0,
    isHidden: false,
};

export const fetchGetDataNotification = createAsyncThunk<ResponseNotification, void>(
    'GetDataNotification/fetchGetDataNotification',
    async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/vendor/notifikasi/rekomendasi`, {
                headers: {
                    Authorization: token,
                    'X-Header-DeviceID': deviceId,
                    'Content-Type': 'application/json',
                },
            });
            console.log('response fetchGetDataNotification ', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching detail toko vendor:', error);
            throw error;
        }
    },
);

const NotificationVendorSlice = createSlice({
    name: 'notificationVendor',
    initialState,
    reducers: {
        setCountBadge: (state, action: PayloadAction<number>) => {
            state.countBadge = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGetDataNotification.pending, (state) => {
                state.loadingResponse = true;
                state.errorResponse = null;
            })
            .addCase(fetchGetDataNotification.fulfilled, (state, action: PayloadAction<ResponseNotification>) => {
                state.dataNotification = action.payload;
                state.loadingResponse = false;
            })
            .addCase(fetchGetDataNotification.rejected, (state, action) => {
                state.loadingResponse = false;
                state.errorResponse = action.error.message || 'Failed to fetch data';
            });
    },
});

export const NotificationVendorService = { action: NotificationVendorSlice.actions };
const NotificationVendorReducer = NotificationVendorSlice.reducer;
export default NotificationVendorReducer;
