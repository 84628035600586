import React from "react";

interface NameInputProps {
  value: string;
  setName: (name: string) => void;
  type: "person" | "store" | "responsible"; // Tipe input yang didukung
}

const NameInput: React.FC<NameInputProps> = ({ value, setName, type }) => {
  // Konfigurasi berdasarkan tipe input
  const config = {
    person: {
      label: "Nama Lengkap",
      placeholder: "Masukkan Nama Anda",
    },
    store: {
      label: "Nama Toko",
      placeholder: "Masukkan Nama Toko Anda",
    },
    responsible: {
      label: "Nama Penanggung Jawab",
      placeholder: "Masukkan Nama Penanggung Jawab",
    },
  };

  // Dapatkan konfigurasi berdasarkan tipe
  const { label, placeholder } = config[type];

  return (
    <div className="mb-6">
      <label className="flex items-center">
        <p className="font-medium text-sm font-inter text-custom-third">{label}</p>
        <span className="text-[#E52427] ml-1">*</span>
      </label>
      <input
        className="input-password w-full max-w-[413px] font-poppins text-sm text-[#404040] border rounded-lg p-3"
        type="text"
        name={type}
        value={value}
        onChange={(e) => setName(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );
};

export default NameInput;
