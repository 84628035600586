import React, { useState } from 'react'
import { useAppDispatch, useTypedSelector } from '../../../../../../../../redux/store';
import { setDataAdmin1, setId, setStep } from '../../../../../../../../redux/store/reducers/supplier/auth/individual';
import GetIndonesianAddress from '../../../../../../../../GlobalComponent/GetAddress';
import GetCoordinate from '../../../../../../../../GlobalComponent/getCoordinate';
import { ArrowRightInputVendor } from '../../../../../../../../Vendor/pages/registerVendor/sections/formAfterSelectRole/icons';
import { googleMapsIcon } from '../../../../../../../../konsumen/assets/images';
import LoadingSpinner from '../../../../../../../../Vendor/Components/loadingSpinner';
import { LogoUploadDocumentGradient } from '../../../../../../../../konsumen/pages/register/sections/formAfterSelectRole/icons';

export default function FirstStep() {
    const dispatch = useAppDispatch()
    const id = useTypedSelector((state) => state.RegisterUser.id)
    const [fullname, setFullname] = useState<string>('');
    const [fullAddress, setFullAddress] = useState<string>('');
    const [detailAddress, setDetailAddress] = useState<string>('');
    const [postalCode, setPostalCode] = useState<string>('');
    const [loadingMaps, setLoadingMaps] = useState<boolean>(false);
    const [ktp, setKtp] = useState<File | null>(null);
    const [lat, setLat] = useState<number>(0);
    const [lng, setLng] = useState<number>(0);
    const [nik, setNik] = useState<number | null>();
    const [npwp, setNpwp] = useState<number | null>();
    const [npwpFile, setNpwpFile] = useState<File | null>(null);
    const [previewKTPUrl, setPreviewKTPUrl] = useState<string | null>(null);
    const [previewNpwpUrl, setPreviewNpwpUrl] = useState<string | null>(null);
    const [getIndonesianAddressModal, setGetIndonesianAddressModal] = useState<boolean>(false);
    const [getCoordinatesModals, setGetCoordinatesModals] = useState<boolean>(false);
    const alamat = fullAddress.split(', ');
    const showCoordinates: boolean = lat !== 0 && lng !== 0
    const isUnactiveEnabled: boolean =
        fullname.length > 0 &&
        fullAddress.length > 0 &&
        postalCode.length > 0
    const handleKtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setNik(value ? parseInt(value, 10) : null);
    };
    const handleRemoveKtp = () => {
        setKtp(null);
        setPreviewKTPUrl(null);
    };
    const handleNpwpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setNpwp(value ? parseInt(value, 10) : null);
    };
    const handleRemoveNpwp = () => {
        setNpwpFile(null);
        setPreviewNpwpUrl(null);
    };
    const handleSubmit = () => {
        if (id && fullname && fullAddress && postalCode) {
            const dataForAdmin1 = {
                nama: fullname,
                fullAddress,
                code_pos: postalCode,
                address_description: detailAddress,
                lat_pin_alamat: lat,
                long_pin_alamat: lng
            };
            dispatch(setStep(2));
            dispatch(setId(id));

            dispatch(setDataAdmin1(dataForAdmin1));
        }
    }

    return (
        <section className="text-start w-full">
            <GetIndonesianAddress
                showModal={getIndonesianAddressModal}
                CloseModals={setGetIndonesianAddressModal}
                setAddress={setFullAddress}
                setKodePos={setPostalCode}
            />
            <GetCoordinate
                showModal={getCoordinatesModals}
                handleCloseMap={setGetCoordinatesModals}
                setLat={setLat}
                setLong={setLng}
                alamat={`${alamat[2]},${alamat[1]}, Indonesia`}
            />

            {/* Nama Lengkap */}
            <div className="mb-6">
                <label className="flex items-center">
                    <p className="font-medium text-sm font-inter text-custom-third">Nama Lengkap</p>
                    <span className="text-[#E52427] ml-1">*</span>
                </label>
                <input
                    className="input-password w-full max-w-[413px] font-poppins text-sm text-[#404040] border rounded-lg p-3"
                    type="text"
                    name="fullname"
                    value={fullname}
                    onChange={(e) => setFullname(e.target.value)}
                    placeholder="Masukan Nama Anda"
                />
            </div>
            {/* Input NIK */}
            <div className="mb-6">
                <label className="block text-sm font-medium text-gray-700 flex items-center">
                    NIK <span className="text-red-500 ml-1">*</span>
                </label>
                <input
                    type="tel"
                    name="nik"
                    placeholder="Masukkan Nomor NIK Anda"
                    className="w-full h-[50px] rounded-lg border border-gray-300 p-4 mt-2 bg-white placeholder-gray-400 outline-none focus:border-blue-500"
                    onChange={handleKtpChange}
                />
                <p className="mt-1 text-xs text-gray-500">
                    Format NIK tidak valid.
                </p>
            </div>

            {/* Unggah KTP */}
            <div className="mb-6">
                <label className="block text-sm font-medium text-gray-700 flex items-center mb-2">
                    Unggah KTP <span className="text-red-500 ml-1">*</span>
                </label>
                {previewKTPUrl === null ? (
                    <div>
                        <div className="flex items-center justify-center">
                            <label className="flex flex-col items-center justify-center rounded-lg cursor-pointer border-2 border-dashed border-[#f46001] w-full h-[118px]">
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <LogoUploadDocumentGradient />
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">Unggah Media</p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">Unggah Foto (PNG,JPG)</p>
                                </div>
                                <input
                                    type="file"
                                    accept=".png,.jpg,.jpeg"
                                    name="imageKTP"
                                    onChange={(e) => {
                                        const files = e.target.files;
                                        if (files && files.length > 0) {
                                            const file = files[0];
                                            setKtp(file);
                                        }
                                    }}
                                    className="dashed-border hidden"
                                />
                            </label>

                        </div>
                        <div className="w-[326px] h-[31px] mt-[10px]">
                            <p className="font-inter font-medium text-sm text-[#A6A7A8] text-left">
                                *Catatan : silakan lakukan unggah Foto, size file (PNG/JPG) Max 1 MB
                            </p>
                        </div>
                    </div>
                ) : (
                    <div className="relative flex items-center justify-start">
                        {previewKTPUrl && (
                            <div className="relative">
                                <img src={previewKTPUrl} alt="Preview NPWP" className="rounded-lg w-[200px] object-cover" />
                                <button
                                    onClick={handleRemoveKtp}
                                    className="bg-[#D9D9D9] p-[3px] ml-[100px] mt-[-14px] text-[14px] rounded-full h-[25px] w-[25px] absolute top-2 -right-[10px] flex justify-center items-center"

                                >
                                    X
                                </button>
                            </div>
                        )}
                    </div>
                )}
                <p className="mt-1 text-xs text-gray-500">
                    *Ukuran file maksimum 1 MB.
                </p>
            </div>

            {/* Alamat Pemilik Toko */}
            <div className="mb-6 relative">
                <label className="flex items-center">
                    <p className="font-medium text-sm font-inter text-custom-third">Alamat</p>
                    <span className="text-[#E52427] ml-1">*</span>
                </label>
                <input
                    className="input-password w-full max-w-[413px] font-poppins text-sm text-[#404040] border rounded-lg p-3 cursor-pointer"
                    type="text"
                    value={fullAddress}
                    placeholder="Provinsi, Kota, Kecamatan, Kode Pos"
                    onFocus={() => setGetIndonesianAddressModal(true)}
                    readOnly
                />
                <div className="absolute bottom-4 right-4">
                    <ArrowRightInputVendor />
                </div>
            </div>

            {/* Pin Alamat */}
            <div className="mb-6">
                <label className="flex items-center">
                    <p className="font-medium text-sm font-inter text-custom-third">Pin Alamat</p>
                    <span className="text-[#E52427] ml-1">*</span>
                </label>
                <div
                    onClick={() => setGetCoordinatesModals(true)}
                    className="w-full max-w-[413px] h-[50px] bg-white rounded-lg border border-[#f46001] flex justify-center items-center cursor-pointer mt-2"
                >
                    <img src={googleMapsIcon} alt="icon-gmaps" />
                    <p className="font-inter font-normal text-sm text-[#f46001] text-center ml-2">
                        {loadingMaps ? (
                            <div className="flex items-center justify-center">
                                <LoadingSpinner width="18px" height="18px" />
                                <span className="ml-2">Sedang Membuka Peta</span>
                            </div>
                        ) : showCoordinates ? (
                            `${lat}, ${lng}`
                        ) : (
                            'Pilih Lewat Peta'
                        )}
                    </p>
                </div>
            </div>

            {/* Detail Alamat */}
            <div className="mb-6">
                <label className="flex items-center">
                    <p className="font-medium text-sm font-inter text-custom-third">Detail Alamat</p>
                    <span className="text-[#E52427] ml-1">*</span>
                </label>
                <textarea
                    className="input-detail-alamat w-full max-w-[413px] font-poppins text-sm text-[#404040] resize-none border rounded-lg p-3"
                    name="detailAddress"
                    onChange={(e) => setDetailAddress(e.target.value)}
                    value={detailAddress}
                    placeholder="Contoh: Jl. Pahlawan No.12, Kel. Sukajadi"
                ></textarea>
            </div>

            {/* Kode Pos */}
            <div className="mb-6">
                <label className="flex items-center">
                    <p className="font-medium text-sm font-inter text-custom-third">Kode Pos</p>
                    <span className="text-[#E52427] ml-1">*</span>
                </label>
                <input
                    className="input-password w-full max-w-[413px] font-poppins text-sm text-[#404040] border rounded-lg p-3"
                    type="number"
                    name="postalCode"
                    readOnly
                    value={postalCode}
                    placeholder="Masukan Kode Pos Anda"
                />
            </div>
            {/* Input NPWP */}
            <div className="mb-6">
                <label className="block text-sm font-medium text-gray-700 flex items-center">
                    NPWP <span className="text-red-500 ml-1">*</span>
                </label>
                <input
                    type="tel"
                    name="npwp"
                    placeholder="Masukkan Nomor NPWP Anda"
                    className="w-full h-[50px] rounded-lg border border-gray-300 p-4 mt-2 bg-white placeholder-gray-400 outline-none focus:border-blue-500"
                    onChange={handleNpwpChange}
                />
                <p className="mt-1 text-xs text-gray-500">
                    Format NPWP tidak valid.
                </p>
            </div>

            {/* Unggah NPWP */}
            <div className="mb-6">
                <label className="block text-sm font-medium text-gray-700 flex items-center mb-2">
                    Unggah NPWP <span className="text-red-500 ml-1">*</span>
                </label>
                {previewNpwpUrl === null ? (
                    <div>
                        <div className="flex items-center justify-center mt-2">
                            <label className="flex flex-col items-center justify-center rounded-lg cursor-pointer border-2 border-dashed border-[#f46001] w-full h-[118px]">
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <LogoUploadDocumentGradient />
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">Unggah Media</p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">Unggah Foto (PNG,JPG)</p>
                                </div>
                                <input
                                    type="file"
                                    accept=".png,.jpg,.jpeg"
                                    name="imageKTP"
                                    onChange={(e) => {
                                        const files = e.target.files;
                                        if (files && files.length > 0) {
                                            const file = files[0];
                                            setNpwpFile(file);
                                        }
                                    }}
                                    className="dashed-border hidden"
                                />
                            </label>

                        </div>
                        <div className="w-[326px] h-[31px] mt-[10px]">
                            <p className="font-inter font-medium text-sm text-[#A6A7A8] text-left">
                                *Catatan : silakan lakukan unggah Foto, size file (PNG/JPG) Max 1 MB
                            </p>
                        </div>
                    </div>
                ) : (
                    <div className="relative flex items-center justify-start">
                        {previewNpwpUrl && (
                            <div className="relative">
                                <img src={previewNpwpUrl} alt="Preview NPWP" className="rounded-lg w-[200px] object-cover" />
                                <button
                                    onClick={handleRemoveNpwp}
                                    className="bg-[#D9D9D9] p-[3px] ml-[100px] mt-[-14px] text-[14px] rounded-full h-[25px] w-[25px] absolute top-2 -right-[10px] flex justify-center items-center"

                                >
                                    X
                                </button>
                            </div>
                        )}
                    </div>
                )}
                <p className="mt-1 text-xs text-gray-500">
                    *Ukuran file maksimum 1 MB.
                </p>
            </div>

            {/* Tombol Submit */}
            <button
                onClick={handleSubmit}
                disabled={!isUnactiveEnabled}
                className={`w-full max-w-[413px] h-[50px] rounded-[100px] py-4 mb-2 ${isUnactiveEnabled ? 'bg-custom-gradient cursor-pointer' : 'bg-[#F3F4F8] cursor-not-allowed'}`}
            >
                <p className={`${isUnactiveEnabled ? 'text-white' : 'text-[#BFBFBF]'} font-medium text-sm font-roboto capitalize`}>
                    lanjut
                </p>
            </button>
        </section>
    )
}
