import React, { useState } from 'react'
import { ArrowRightInputVendor } from '../../../../../../../Vendor/pages/registerVendor/sections/formAfterSelectRole/icons'
import GetIndonesianAddress from '../../../../../../../GlobalComponent/GetAddress'
import GetCoordinate from '../../../../../../../GlobalComponent/getCoordinate'
import LoadingSpinner from '../../../../../../../Vendor/Components/loadingSpinner'
import { googleMapsIcon } from '../../../../../../../konsumen/assets/images'

interface addressAndCoordinateProps {
    setPostalCode: (postalCode: string) => void
}
export default function AddressAndCoordinateInput({setPostalCode}: addressAndCoordinateProps) {
    const [fullAddress, setFullAddress] = useState('')
    const alamat = fullAddress.split(', ');
    const [lat, setLat] = useState<number>(0);
    const [lng, setLng] = useState<number>(0);
    const [showGetAddressModal, setShowGetAddressModal] = useState(false)
    const [showGetCoordinateModal, setShowGetCoordinateModal] = useState(false)
    const showCoordinates: boolean = lat !== 0 && lng !== 0
    const loadingMaps = false

    return (
        <>
            <GetIndonesianAddress
                showModal={showGetAddressModal}
                CloseModals={setShowGetAddressModal}
                setAddress={setFullAddress}
                setKodePos={setPostalCode}
            />
            <GetCoordinate
                showModal={showGetCoordinateModal}
                handleCloseMap={setShowGetCoordinateModal}
                setLat={setLat}
                setLong={setLng}
                alamat={`${alamat[2]},${alamat[1]}, Indonesia`}
            />
            <div className="mb-6 relative">
                <label className="flex items-center">
                    <p className="font-medium text-sm font-inter text-custom-third">Alamat</p>
                    <span className="text-[#E52427] ml-1">*</span>
                </label>
                <input
                    className="input-password w-full max-w-[413px] font-poppins text-sm text-[#404040] border rounded-lg p-3 cursor-pointer"
                    type="text"
                    value={fullAddress}
                    placeholder="Provinsi, Kota, Kecamatan, Kode Pos"
                    onFocus={() => setShowGetAddressModal(true)}
                    readOnly
                />
                <div className="absolute bottom-4 right-4">
                    <ArrowRightInputVendor />
                </div>
            </div>

            {/* Pin Alamat */}
            <div className="mb-6">
                <label className="flex items-center">
                    <p className="font-medium text-sm font-inter text-custom-third">Pin Alamat</p>
                    <span className="text-[#E52427] ml-1">*</span>
                </label>
                <div
                    onClick={() => setShowGetCoordinateModal(true)}
                    className="w-full max-w-[413px] h-[50px] bg-white rounded-lg border border-[#f46001] flex justify-center items-center cursor-pointer mt-2"
                >
                    <img src={googleMapsIcon} alt="icon-gmaps" />
                    <p className="font-inter font-normal text-sm text-[#f46001] text-center ml-2">
                        {loadingMaps ? (
                            <div className="flex items-center justify-center">
                                <LoadingSpinner width="18px" height="18px" />
                                <span className="ml-2">Sedang Membuka Peta</span>
                            </div>
                        ) : showCoordinates ? (
                            `${lat}, ${lng}`
                        ) : (
                            'Pilih Lewat Peta'
                        )}
                    </p>
                </div>
            </div>
        </>
    )
}
