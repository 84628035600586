import React from 'react'
interface kodePosProps {
    kodepos: string
}

export default function KodePosInput({kodepos}: kodePosProps) {
    return (
        <div className="mb-6">
            <label className="flex items-center">
                <p className="font-medium text-sm font-inter text-custom-third">Kode Pos</p>
                <span className="text-[#E52427] ml-1">*</span>
            </label>
            <input
                className="input-password w-full max-w-[413px] font-poppins text-sm text-[#404040] border rounded-lg p-3"
                type="number"
                name="postalCode"
                readOnly
                value={kodepos}
                placeholder="Masukan Kode Pos Anda"
            />
        </div>
    )
}
