import React from 'react'

export default function SameAddressCheckBox() {
  return (
    <section className="flex gap-4">
        <input type="checkbox" />
        <p>Alamat toko Sama Dengan Alamat Perusahaan</p>
      </section>
  )
}
