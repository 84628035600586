import React, { useEffect, useState } from 'react'
import { useAppDispatch, useTypedSelector } from '../../../../../../../../redux/store';
import { setDataToko } from '../../../../../../../../redux/store/reducers/supplier/auth/individual';
import axiosInstance from '../../../../../../../../api/axiosInstance';
import GetIndonesianAddress from '../../../../../../../../GlobalComponent/GetAddress';
import GetCoordinate from '../../../../../../../../GlobalComponent/getCoordinate';
import { ArrowRightInputVendor } from '../../../../../../../../Vendor/pages/registerVendor/sections/formAfterSelectRole/icons';
import LoadingSpinner from '../../../../../../../../Vendor/Components/loadingSpinner';
import { googleMapsIcon } from '../../../../../../../../konsumen/assets/images';
import { LogoUploadDocumentGradient } from '../../../../../../../../konsumen/pages/register/sections/formAfterSelectRole/icons';
export default function ThirdStep() {
  const dispatch = useAppDispatch()
  const id = useTypedSelector((state) => state.RegisterUser.id);
  const payload = useTypedSelector((state) => state.ProdusenRegistIndividual);
  const [getIndonesianAddressModal, setGetIndonesianAddressModal] = useState(false);
  const [getCoordinatesModals, setGetCoordinatesModals] = useState(false);
  const [loadingMaps, setLoadingMaps] = useState<boolean>(false);
  const [fullname, setFullname] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [fullAddress, setFullAddress] = useState<string>('');
  const alamat = fullAddress.split(', ');
  const [detailAddress, setDetailAddress] = useState<string>('');
  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const [KTP, setKTP] = useState<File | null>(null);
  const [previewKTPUrl, setPreviewKTPUrl] = useState<string | null>(null);
  const [NPWP, setNPWP] = useState<File | null>(null);
  const [previewNPWPUrl, setPreviewNPWPUrl] = useState<string | null>(null);
  const showCoordinates: boolean = lat !== 0 && lng !== 0
  const isUnactiveEnabled: boolean =
    fullname.length > 0 &&
    fullAddress.length > 0 &&
    postalCode.length > 0
  const isDisabledBtn = false
  const handleRemoveKTP = () => {
    setPreviewKTPUrl(null)
    setKTP(null)
  }
  const handleRemoveNPWP = () => {
    setPreviewKTPUrl(null)
    setKTP(null)
  }
  const handleSubmit = async () => {
    const dataToko = {
      id,
      namaToko: fullname,
      fullAddress,
      code_pos: postalCode,
      address_description: detailAddress,
      lat_pin_alamat: lat,
      long_pin_alamat: lng,
    }
    const resultAction = await dispatch(setDataToko(dataToko));

    // Cek apakah dispatch berhasil dan data telah masuk
    if (setDataToko.fulfilled.match(resultAction)) {
      // Dispatch berhasil, lakukan console log pada payload
      alert('Registrasi berhasil, anda akan dilanjutkan ke halaman create password');
    } else {
      // Jika terjadi error atau dispatch gagal
      console.error("Gagal menyimpan data toko:", resultAction.error);
    }
  }

  const handleCreateUser = () => {
    const address = payload.dataAdmin.fullAddress.split(', ')
    const body = {
      registerAs: payload.registAs,
      id: payload.id,
      nama: payload.dataAdmin.nama,
      province: address[0],
      regency: address[1],
      district: address[2],
      village: address[3],
      lat_pin_alamat: payload.dataAdmin.lat_pin_alamat,
      long_pin_alamat: payload.dataAdmin.long_pin_alamat,
      address_description: payload.dataAdmin.address_description,
      code_pos: payload.dataAdmin.code_pos,
      nik: payload.dataAdmin.nik,
      nomorNpwp: payload.dataAdmin.nomor_npwp,
      file_ktp: payload.dataAdmin.file_ktp,
      npwpFile: payload.dataAdmin.npwpFile
    }
    // axiosInstance.post(`/produsen/create`, body, {
    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //   }
    // })
    //   .then((res) => {
    //     return true
    //   })
    //   .catch((err) => {
    //     return false
    //   })
  }
  const handleCreateToko = () => {
    const address = payload.dataToko.fullAddress.split(', ')
    const body = {
      registerAs: payload.registAs,
      id: payload.id,
      namaToko: payload.dataToko.namaToko,
      province: address[0],
      regency: address[1],
      district: address[2],
      village: address[3],
      lat_pin_alamat: payload.dataToko.lat_pin_alamat,
      long_pin_alamat: payload.dataToko.long_pin_alamat,
      address_description: payload.dataToko.address_description,
      code_pos: payload.dataToko.code_pos,
    }
    // axiosInstance.post(`/produsen/create/toko`, body)
    //   .then((res) => {
    //     return true
    //   }).catch((err) => {
    //     return false
    //   })
  }
  useEffect(() => {
    if (payload.dataAdmin.nama !== '' && payload.dataToko.namaToko !== '') {
      console.log(payload)
      const dataUser = handleCreateUser()
      const dataToko = handleCreateToko()
      console.log(dataUser, dataToko)
    }
  }, [dispatch, payload]);

  return (
    <section className="text-start w-full">
      <GetIndonesianAddress
        showModal={getIndonesianAddressModal}
        CloseModals={setGetIndonesianAddressModal}
        setAddress={setFullAddress}
        setKodePos={setPostalCode}
      />
      <GetCoordinate
        showModal={getCoordinatesModals}
        handleCloseMap={setGetCoordinatesModals}
        setLat={setLat}
        setLong={setLng}
        alamat={`${alamat[2]},${alamat[1]}, Indonesia`}
      />

      {/* Nama Lengkap */}
      <div className="mb-6">
        <label className="flex items-center">
          <p className="font-medium text-sm font-inter text-custom-third">Nama Penanggung Jawab</p>
          <span className="text-[#E52427] ml-1">*</span>
        </label>
        <input
          className="capitalize input-password w-full max-w-[413px] font-poppins text-sm text-[#404040] border rounded-lg p-3"
          type="text"
          name="fullname"
          value={fullname}
          onChange={(e) => setFullname(e.target.value)}
          placeholder="Masukan Nama Penanggung Jawab"
        />
      </div>
      {/* Jabatan Penanggung Jawab */}
      <div className="mb-6">
        <label className="flex items-center">
          <p className="font-medium text-sm font-inter text-custom-third">Jabatan Penanggung Jawab</p>
          <span className="text-[#E52427] ml-1">*</span>
        </label>
        <input
          className="capitalize input-password w-full max-w-[413px] font-poppins text-sm text-[#404040] border rounded-lg p-3"
          type="text"
          disabled
          name="fullname"
          onChange={(e) => setFullname(e.target.value)}
          placeholder="Masukan Jabatan Penanggung Jawab"
        />
      </div>
      {/* NIK */}
      <div className="mb-6">
        <label className="flex items-center">
          <p className="font-medium text-sm font-inter text-custom-third">NIK</p>
          <span className="text-[#E52427] ml-1">*</span>
        </label>
        <input
          className="capitalize input-password w-full max-w-[413px] font-poppins text-sm text-[#404040] border rounded-lg p-3"
          type="text"
          disabled
          name="fullname"
          onChange={(e) => setFullname(e.target.value)}
          placeholder="Masukan Nomor Induk Kependudukan"
        />
      </div>
      {/* file KTP */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 flex items-center mb-2">
          Unggah KTP<span className="text-red-500 ml-1">*</span>
        </label>
        {previewKTPUrl === null ? (
          <div>
            <div className="flex items-center justify-center">
              <label className="flex flex-col items-center justify-center rounded-lg cursor-pointer border-2 border-dashed border-[#f46001] w-full h-[118px]">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <LogoUploadDocumentGradient />
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">Unggah Media</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">Unggah Foto (PNG,JPG)</p>
                </div>
                <input
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  name="imageKTP"
                  onChange={(e) => {
                    const files = e.target.files;
                    if (files && files.length > 0) {
                      const file = files[0];
                      setKTP(file);
                    }
                  }}
                  className="dashed-border hidden"
                />
              </label>

            </div>
            <div className="w-[326px] h-[31px] mt-[10px]">
              <p className="font-inter font-medium text-sm text-[#A6A7A8] text-left">
                *Catatan : silakan lakukan unggah Foto, size file (PNG/JPG) Max 1 MB
              </p>
            </div>
          </div>
        ) : (
          <div className="relative flex items-center justify-start">
            {previewKTPUrl && (
              <div className="relative">
                <img src={previewKTPUrl} alt="Preview NPWP" className="rounded-lg w-[200px] object-cover" />
                <button
                  onClick={handleRemoveKTP}
                  className="bg-[#D9D9D9] p-[3px] ml-[100px] mt-[-14px] text-[14px] rounded-full h-[25px] w-[25px] absolute top-2 -right-[10px] flex justify-center items-center"

                >
                  X
                </button>
              </div>
            )}
          </div>
        )}
        <p className="mt-1 text-xs text-gray-500">
          *Ukuran file maksimum 1 MB.
        </p>
      </div>

      {/* Alamat Pemilik Toko */}
      <div className="mb-6 relative">
        <label className="flex items-center">
          <p className="font-medium text-sm font-inter text-custom-third">Alamat</p>
          <span className="text-[#E52427] ml-1">*</span>
        </label>
        <input
          className="input-password w-full max-w-[413px] font-poppins text-sm text-[#404040] border rounded-lg p-3 cursor-pointer"
          type="text"
          value={fullAddress}
          placeholder="Provinsi, Kota, Kecamatan, Kode Pos"
          onFocus={() => setGetIndonesianAddressModal(true)}
          readOnly
        />
        <div className="absolute bottom-4 right-4">
          <ArrowRightInputVendor />
        </div>
      </div>

      {/* Pin Alamat */}
      <div className="mb-6">
        <label className="flex items-center">
          <p className="font-medium text-sm font-inter text-custom-third">Pin Alamat</p>
          <span className="text-[#E52427] ml-1">*</span>
        </label>
        <div
          onClick={() => setGetCoordinatesModals(true)}
          className="w-full max-w-[413px] h-[50px] bg-white rounded-lg border border-[#f46001] flex justify-center items-center cursor-pointer mt-2"
        >
          <img src={googleMapsIcon} alt="icon-gmaps" />
          <p className="font-inter font-normal text-sm text-[#f46001] text-center ml-2">
            {loadingMaps ? (
              <div className="flex items-center justify-center">
                <LoadingSpinner width="18px" height="18px" />
                <span className="ml-2">Sedang Membuka Peta</span>
              </div>
            ) : showCoordinates ? (
              `${lat}, ${lng}`
            ) : (
              'Pilih Lewat Peta'
            )}
          </p>
        </div>
      </div>

      {/* Detail Alamat */}
      <div className="mb-6">
        <label className="flex items-center">
          <p className="font-medium text-sm font-inter text-custom-third">Detail Alamat</p>
          <span className="text-[#E52427] ml-1">*</span>
        </label>
        <textarea
          className="input-detail-alamat w-full max-w-[413px] font-poppins text-sm text-[#404040] resize-none border rounded-lg p-3"
          name="detailAddress"
          value={detailAddress}
          onChange={(e) => setDetailAddress(e.target.value)}
          placeholder="Contoh: Jl. Pahlawan No.12, Kel. Sukajadi"
        ></textarea>
      </div>

      {/* Kode Pos */}
      <div className="mb-6">
        <label className="flex items-center">
          <p className="font-medium text-sm font-inter text-custom-third">Kode Pos</p>
          <span className="text-[#E52427] ml-1">*</span>
        </label>
        <input
          className="input-password w-full max-w-[413px] font-poppins text-sm text-[#404040] border rounded-lg p-3"
          type="number"
          name="postalCode"
          readOnly
          value={postalCode}
          placeholder="Masukan Kode Pos Anda"
        />
      </div>
      {/* NPMWP */}
      <div className="mb-6">
        <label className="flex items-center">
          <p className="font-medium text-sm font-inter text-custom-third">NPWP</p>
          <span className="text-[#E52427] ml-1">*</span>
        </label>
        <input
          className="input-password w-full max-w-[413px] font-poppins text-sm text-[#404040] border rounded-lg p-3"
          type="text"
          name="fullname"
          value={fullname}
          // onChange={(e) => setFullname(e.target.value)}
          placeholder="Masukan nomor NPWP"
        />
      </div>
      {/* file NPWP */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 flex items-center mb-2">
          Unggah NPWP <span className="text-red-500 ml-1">*</span>
        </label>
        {previewNPWPUrl === null ? (
          <div>
            <div className="flex items-center justify-center">
              <label className="flex flex-col items-center justify-center rounded-lg cursor-pointer border-2 border-dashed border-[#f46001] w-full h-[118px]">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <LogoUploadDocumentGradient />
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">Unggah Media</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">Unggah Foto (PNG,JPG)</p>
                </div>
                <input
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  name="imageKTP"
                  onChange={(e) => {
                    const files = e.target.files;
                    if (files && files.length > 0) {
                      const file = files[0];
                      setNPWP(file);
                    }
                  }}
                  className="dashed-border hidden"
                />
              </label>

            </div>
            <div className="w-[326px] h-[31px] mt-[10px]">
              <p className="font-inter font-medium text-sm text-[#A6A7A8] text-left">
                *Catatan : silakan lakukan unggah Foto, size file (PNG/JPG) Max 1 MB
              </p>
            </div>
          </div>
        ) : (
          <div className="relative flex items-center justify-start">
            {previewNPWPUrl && (
              <div className="relative">
                <img src={previewNPWPUrl} alt="Preview NPWP" className="rounded-lg w-[200px] object-cover" />
                <button
                  onClick={handleRemoveNPWP}
                  className="bg-[#D9D9D9] p-[3px] ml-[100px] mt-[-14px] text-[14px] rounded-full h-[25px] w-[25px] absolute top-2 -right-[10px] flex justify-center items-center"

                >
                  X
                </button>
              </div>
            )}
          </div>
        )}
        <p className="mt-1 text-xs text-gray-500">
          *Ukuran file maksimum 1 MB.
        </p>
      </div>



      {/* Tombol Submit */}
      <div className="flex justify-between mt-8 gap-3">
        <button
          type="button"
          // onClick={() => dispatch(setStep(1))}
          className="w-1/3 h-[50px] border border-[#f46001] rounded-full 
                    bg-white py-3 px-3  font-roboto font-medium text-[14px] text-[#f46001] capitalize">
          Kembali
        </button>
        <button
          type="button"
          onClick={handleSubmit}
          className={`w-2/3 ${isDisabledBtn ? 'bg-custom-gradient text-white' : 'bg-[#F3F4F8]'} 
            ${isDisabledBtn ? 'cursor-pointer' : 'cursor-not-allowed'} h-[50px] rounded-[100px] outline-none`}
        >
          Lanjut
        </button>
      </div>
    </section>
  )
}

